<template>
  <div class="pet-Insurances">
    <Subnavbar back="petOwnerHome" :mini="true">
      <template slot="title">ระบบบันทึกประกันสัตว์เลี้ยง</template>
    </Subnavbar>

    <div class="form-block standalone">
      <div class="header-block">
        <div class="title">บันทึกประกันสัตว์เลี้ยง</div>
        <div class="grid justify-items-center">
          <el-image
            style="width: 100px; height: 100px"
            :src="pet?.image?.medium"
            lazy
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="lead">{{ pet?.name }}</div>
        <div class="lead text-gray-400">{{ pet?.type?.name }}</div>
        <div class="lead text-gray-400">{{ pet?.breed?.name }}</div>
      </div>
      <el-collapse-transition>
        <el-form
          v-show="!tabPreview"
          :model="form"
          :rules="rules"
          ref="FormInsurances"
        >
          <el-form-item prop="company">
            <span class="form-label">บริษัทประกัน </span>
            <el-select
              :disabled="insuranceFlagEdit"
              v-model="form.company"
              name="selectedCompany"
              placeholder="เลือกรายการ"
              style="width: 100%; margin-top: 5px"
            >
              <el-option
                v-for="(item, index) in companiesList"
                :key="index"
                :label="item?.name"
                :value="item?.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="plan">
            <span class="form-label">แผนประกัน </span>
            <el-select
              :disabled="insuranceFlagEdit"
              name="selectedPlan"
              placeholder="เลือกรายการ"
              style="width: 100%; margin-top: 5px"
              v-model="form.plan"
              :loading="loadingPlan"
            >
              <el-option
                v-for="(item, index) in planList"
                :key="index"
                :label="item?.name"
                :value="item?.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="insurance_number">
            <span class="form-label">เลขกรมธรรม์</span>
            <el-input
              :disabled="insuranceFlagEdit"
              v-model="form.insurance_number"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="start_date">
            <span class="form-label">วันที่เริ่ม </span>
            <el-date-picker
              v-model="form.start_date"
              type="date"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item prop="end_date">
            <span class="form-label">วันที่สิ้นสุด </span>
            <el-date-picker
              v-model="form.end_date"
              type="date"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <div class="grid justify-items-end">
              <div>
                <div class="flex items-center">
                  <span class="mr-2">กรมธรรม์ / ใบเคลม</span>
                  <el-button
                    type="primary"
                    style="padding: 5px 5px"
                    @click="$refs.file.click()"
                  >
                    <img
                      src="@/assets/icons/camera.png"
                      alt="iTaam Logo"
                      width="25px"
                    />
                  </el-button>
                </div>
                <input
                  multiple
                  type="file"
                  id="file"
                  ref="file"
                  style="display: none"
                  @change="handleFileUpload"
                />
              </div>
              <div class="justify-self-start">
                <!-- <transition-group name="el-zoom-in-top"> -->
                <p
                  v-show="form.insurance_document"
                  style="line-height: normal"
                  class="text-gray-400"
                >
                  <i class="el-icon-tickets"></i>
                  {{ shortenFilename(form.insurance_document) }}
                  <el-link type="danger" @click="removeFile()">
                    <i class="el-icon-close red"></i
                  ></el-link>
                </p>
                <!-- </transition-group> -->
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-checkbox label="" v-model="form.share_with_hospital">
              ยินยอมให้สัตวแพทย์เข้าถึงข้อมูลที่บันทึกลงไปได้
            </el-checkbox>
          </el-form-item>
        </el-form>
      </el-collapse-transition>

      <el-collapse-transition>
        <div v-show="tabPreview" class="transition-box mt-4">
          <div class="flex space-x-4">
            <div class="flex-none text-gray-400">บริษัทประกัน :</div>
            <div class="shrink">{{ form?.companyName }}</div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-none text-gray-400">แผนความคุ้มครอง : :</div>
            <div class="shrink">{{ form?.planName }}</div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-none text-gray-400">เลขกรมธรรม์ :</div>
            <div class="shrink">{{ form?.insurance_number }}</div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-none text-gray-400">วันที่เริ่ม :</div>
            <div class="shrink">{{ displayDateFormat(form?.start_date) }}</div>
          </div>
          <div class="flex space-x-4">
            <div class="flex-none text-gray-400">วันที่สิ้นสุด :</div>
            <div class="shrink">{{ displayDateFormat(form?.end_date) }}</div>
          </div>
          <div class="flex space-x-4 mt-4">
            <div class="flex-none text-gray-400">กรมธรรม์ / ใบเคลม</div>
          </div>

          <div class="grid grid-cols-2 gap-2" v-show="form?.insurance_document">
            <el-link
              v-if="!/^image+/.test(form?.insurance_document?.type)"
              class="pdf-container"
              @click="openFile(form?.insurance_document)"
              :underline="false"
              rel="norefferer"
            >
              PDF
            </el-link>
            <el-image
              v-else
              style="width: 150px; height: 150px; cursor: zoom-in"
              :src="readImage(form?.insurance_document)"
              fit="cover"
              @click="showDialogImage(readImage(form?.insurance_document))"
            ></el-image>
          </div>
        </div>
      </el-collapse-transition>

      <div
        class="type-select grid grid-cols-2 gap-2 mt-4"
        v-if="!insuranceFlagEdit"
      >
        <div class="flex">
          <el-button
            class="grow"
            @click="tabPreview ? (tabPreview = false) : $router.go(-1)"
          >
            <div class="flex">
              <div>
                <i class="el-icon-arrow-left"></i>
              </div>
              <div class="grow">
                <span class="ml-2"> กลับ</span>
              </div>
            </div>
          </el-button>
        </div>
        <div class="flex" v-show="!tabPreview">
          <el-button class="grow" type="primary" @click="validateForm">
            <div class="flex">
              <div class="grow">
                <span class="ml-2"> ถัดไป</span>
              </div>
              <div>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </el-button>
        </div>
        <div class="flex" v-show="tabPreview">
          <el-button class="grow" type="primary" @click="submitForm">
            <div class="flex">
              <div class="grow">
                <span class="ml-2"> ยืนยัน</span>
              </div>
              <!-- <div>
                <i class="el-icon-arrow-right"></i>
              </div> -->
            </div>
          </el-button>
        </div>
      </div>

      <div class="type-select grid grid-cols-2 gap-2 mt-2" v-else>
        <div class="flex" v-if="tabPreview === true">
          <el-button class="grow" @click="dialogConfirm = true">
            <div class="flex">
              <div class="grow">
                <span class="ml-2"> ลบ</span>
              </div>
            </div>
          </el-button>
        </div>
        <div class="flex" v-if="tabPreview === true">
          <el-button class="grow" @click="tabPreview = false">
            <div class="flex">
              <div class="grow">
                <span class="ml-2"> แก้ไข</span>
              </div>
            </div>
          </el-button>
        </div>
        <div class="flex" v-if="tabPreview === false">
          <el-button class="grow" @click="tabPreview = true">
            <div class="flex">
              <div class="grow">
                <span class="ml-2"> กลับ</span>
              </div>
            </div>
          </el-button>
        </div>
        <div class="flex" v-if="tabPreview === false">
          <el-button class="grow" @click="dialogConfirmEdit = true">
            <div class="flex">
              <div class="grow">
                <span class="ml-2"> บันทึก</span>
              </div>
            </div>
          </el-button>
        </div>
      </div>
    </div>
    <ShowImage ref="dialogImage" />

    <el-dialog
      title="ยืนยันการลบ"
      custom-class="el-dialog-preview"
      :close-on-click-modal="false"
      :visible.sync="dialogConfirm"
      center
    >
      <div class="grid justify-items-center">
        <div class="w-3/4 whitespace-pre-line text-center">
          เมื่อลบแผนประกัน กับประวัติการเคลม นี้ออกแล้ว
          จะไม่สามารถเรียกกลับมาได้อีก ยืนยันการลบหรือไม่
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogConfirm = false">ยกเลิก</el-button>
        <el-button type="primary" @click="deleteInsurance()">ยืนยัน</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="ยืนยันการแก้ไข"
      custom-class="el-dialog-preview"
      :close-on-click-modal="false"
      :visible.sync="dialogConfirmEdit"
      center
    >
      <div class="grid justify-items-center">
        <div class="w-2/3 whitespace-pre-line">
          <el-row class="mt-2">
            <el-col class="text-sm text-gray-400">วันที่เริ่ม </el-col>
            <el-col class="text-base" v-if="form?.start_date">{{
              displayDateFormat(form?.start_date)
            }}</el-col>
            <el-col v-else>-</el-col>
          </el-row>
          <el-row class="mt-2">
            <el-col class="text-sm text-gray-400">วันที่สิ้นสุด </el-col>
            <el-col class="text-base" v-if="form?.end_date">{{
              displayDateFormat(form?.end_date)
            }}</el-col>
            <el-col v-else>-</el-col>
          </el-row>
        </div>
        <div class="w-10/12 whitespace-pre-line">
          <el-row>
            <el-col>
              <span
                v-if="form.share_with_hospital"
                class="text-lg font-extrabold"
                >ยินยอม</span
              >
              <span v-else class="text-lg font-extrabold">ไม่ยินยอม</span>
              ให้สถานพยาบาลเข้าถึงข้อมูลที่บันทึกลงไปได้
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogConfirmEdit = false">ยกเลิก</el-button>
        <el-button type="primary" @click="updateInsurance()">ยืนยัน</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Subnavbar from "@/components/navigation/Subnavbar";
import ShowImage from "@/components/dialog/pet/ShowImage.vue";
import { get } from "vuex-pathify";
export default {
  components: {
    Subnavbar,
    ShowImage,
  },
  data() {
    return {
      rules: {
        company: [
          {
            required: true,
            message: "กรุณาเลื่อกรายการ",
            trigger: "change",
          },
        ],
        plan: [
          {
            required: true,
            message: "กรุณาเลื่อกรายการ",
            trigger: "change",
          },
        ],
        insurance_number: [
          {
            required: true,
            message: "กรุณาระบุ",
            trigger: "blur",
          },
        ],
        start_date: [
          {
            required: true,
            message: "กรุณาระบวันที่",
            trigger: "change",
          },
        ],
        end_date: [
          {
            required: true,
            message: "กรุณาระบวันที่",
            trigger: "change",
          },
        ],
      },
      form: {
        company: null,
        companyName: null,
        plan: null,
        planName: null,
        insurance_number: null,
        insurance_document: null,
        insurance_claim_document: null,
        start_date: null,
        end_date: null,
        share_with_hospital: false,
      },
      tabPreview: false,
      companiesList: [],
      planList: [],
      loadingPlan: false,
      dialogConfirm: false,
      dialogConfirmEdit: false,
    };
  },
  computed: {
    pet: get("owner/pet"),
    insuranceForm: get("insurance/insuranceForm"),
    insuranceFlagEdit: get("insurance/insuranceFlagEdit"),
  },
  watch: {
    async "form.company"(id) {
      if (id) {
        this.form.plan = null;
        this.loadingPlan = true;

        const item = this.companiesList.find((it) => it.id === id);
        this.form.companyName = item.name;

        const data = await this.$store.dispatch("insurance/getListPlan", {
          query: {
            company: id,
          },
        });
        this.planList = data;

        this.loadingPlan = false;
      }
    },
    "form.plan"(id) {
      console.log("🚀 ~ file: PetInsurances.vue:404 ~ id:", id);
      if (!id) return false;
      const item = this.planList.find((it) => it.id === id);
      console.log("🚀 ~ file: PetInsurances.vue:402 ~ item:", item);
      this.form.planName = item.name;
    },
  },
  async mounted() {
    await this.fetchData();

    if (this.insuranceFlagEdit) {
      this.setForm();
      this.tabPreview = true;
    }
  },
  methods: {
    async fetchData() {
      this.tabPreview = false;
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      const data = await this.$store.dispatch("insurance/getListCompanies");
      this.companiesList = data;

      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    },

    async setForm() {
      this.form.company = this.insuranceForm.plan.company.id;
      this.$nextTick(() => {
        this.form = {
          company: this.insuranceForm.plan.company.id,
          companyName: this.insuranceForm.plan.company.name,
          plan: this.insuranceForm.plan.id,
          planName: this.insuranceForm.plan.name,
          insurance_number: this.insuranceForm.insurance_number,
          insurance_document: this.insuranceForm.insurance_document,
          insurance_claim_document: this.insuranceForm.insurance_claim_document,
          start_date: this.insuranceForm.start_date,
          end_date: this.insuranceForm.end_date,
          share_with_hospital: this.insuranceForm.share_with_hospital,
        };
        console.log("this.form 11  :>> ", this.form);
      });

      console.log("this.form  :>> ", this.form);
    },
    displayDateFormat(date) {
      return this.$options.filters.toThaiDate(date);
    },
    validateForm() {
      this.$refs.FormInsurances.validate((valid) => {
        if (valid) {
          this.tabPreview = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleFileUpload(files) {
      if (!files) return false;

      Object.entries(files.target.files).forEach((entry) => {
        const [, value] = entry;
        this.form.insurance_document = value;
      });
    },
    removeFile() {
      this.form.insurance_document = null;
    },
    async submitForm() {
      try {
        let formData = new FormData();
        formData.append("plan", this.form.plan);
        formData.append("insurance_number", this.form.insurance_number);
        if (this.form?.insurance_document) {
          formData.append(
            "insurance_document",
            this.form?.insurance_document,
            this.form?.insurance_document?.name
          );
        }

        formData.append("start_date", this.form.start_date);
        formData.append("end_date", this.form.end_date);

        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("pet/saveInsurance", {
          data: formData,
          params: { id: this.pet.id },
        });
        this.$router.push({
          name: "petProfile",
          params: { id: this.pet.id },
          query: { tab: "insurance" },
        });
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      } finally {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      }
    },
    showProfile() {
      if (this.pet?.owners?.length) {
        return this.pet?.owners[0]?.profile?.firstname;
      } else {
        return "-";
      }
    },
    openFile(file) {
      var fileURL = null;
      if (/^http|https+/.test(file)) {
        fileURL = file;
      } else {
        fileURL = URL.createObjectURL(file);
      }

      window.open(fileURL);
    },
    showDialogImage(src) {
      this.$refs.dialogImage.show(src);
    },
    readImage(file) {
      return URL.createObjectURL(file);
    },
    shortenFilename(fileName) {
      if (!fileName) return null;
      var file_name = fileName.name || fileName;
      if (file_name.length > 35) {
        return (file_name =
          file_name.substr(0, 35) + "..." + file_name.substr(-7));
      } else {
        return fileName;
      }
    },
    uploadInsuranceDocument(file) {
      this.form.insurance_document = file;
    },
    uploadInsuranceClaimDocument(file) {
      this.form.insurance_claim_document = file;
    },
    async updateInsurance() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        let formData = new FormData();
        formData.append("plan", this.form.plan);
        formData.append("share_with_hospital", this.form.share_with_hospital);
        formData.append("insurance_number", this.form.insurance_number);
        if (
          this.form?.insurance_document &&
          typeof this.form?.insurance_document === Blob
        ) {
          formData.append(
            "insurance_document",
            this.form?.insurance_document,
            this.form?.insurance_document?.name || this.form?.insurance_document
          );
        }

        formData.append("start_date", this.form.start_date);
        formData.append("end_date", this.form.end_date);

        await this.$store.dispatch("pet/updateInsurance", {
          data: formData,
          params: { id: this.pet.id, insuranceId: this.insuranceForm.id },
        });
        this.$store.commit("insurance/SET_INSURANCE_FORM", null);
        this.$store.commit("insurance/SET_INSURANCE_FLAG_EDIT", false);
        this.$router.push({
          name: "petProfile",
          params: { id: this.pet.id },
          query: { tab: "insurance" },
        });
      } catch (error) {
        console.log(
          "🚀 ~ file: PetInsurances.vue:557 ~ updateInsurance ~ error:",
          error
        );
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      } finally {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      }
    },
    async deleteInsurance() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);

        await this.$store.dispatch("pet/deleteInsurance", {
          params: { id: this.pet.id, insuranceId: this.insuranceForm.id },
        });
        this.$store.commit("insurance/SET_INSURANCE_FORM", null);
        this.$store.commit("insurance/SET_INSURANCE_FLAG_EDIT", false);
        this.$router.push({
          name: "petProfile",
          params: { id: this.pet.id },
          query: { tab: "insurance" },
        });
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      } finally {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.pet-Insurances {
  margin-top: 0px;
  padding: 0px 10px 40px 10px;
}

.form-block {
  &.standalone {
    @extend %block-container;
  }
  @extend %form-style;
  .form-submit-btn {
    width: 160px;
  }
  .el-button {
    max-height: 40px;
    padding: 12px 5px;
  }

  .type-select {
    .el-button {
      width: 48%;
    }
  }
  .el-checkbox {
    white-space: pre-wrap;
    display: flex;
  }

  .btn-remove {
    background: none;
    padding: 8px;
    border: none;
    // width: 30px;
    height: 100%;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .el-input:not(first-child) {
    margin-top: 4px;
  }
  .pdf-container {
    width: 150px;
    height: 150px;
    border-radius: 5px;
    background-color: #02b0e1;
    font-size: 24px;
    color: #fff;
    font-weight: bold;
  }
}
</style>