<template>
  <div class="max-w-lg mx-auto px-4 requestAppointmentOwner">
    <div class="bg-white shadow-sm px-6 py-4">
      <div class="grid justify-center justify-items-center">
        <div>ยินดีต้อนรับสู่ระบบนัดหมาย</div>
        <div class="my-2" v-if="hospital && hospital?.logo">
          <div>
            <img :src="hospital?.logo?.large" width="100px" />
          </div>
        </div>
        <div>
          {{ hospital?.name }}
        </div>
      </div>

      <div class="mt-4">
        <el-form :model="form" ref="FormAppOwner">
          <el-form-item
            prop="date"
            :rules="[{ required: true, message: 'date is required' }]"
          >
            <span class="form-label"
              >1. วันเวลาที่นัด <span class="text-red-500">*</span></span
            >
            <el-date-picker
              v-model="form.date"
              type="date"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
              :picker-options="pickerDateOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            prop="time"
            :rules="[{ required: true, message: 'date is required' }]"
          >
            <el-time-picker
              v-model="form.time"
              :picker-options="{
                format: 'HH:mm',
              }"
              value-format="hh:mm"
              style="width: 100%"
            >
            </el-time-picker>
          </el-form-item>

          <el-form-item
            prop="owner_name"
            :rules="[{ required: true, message: 'name is required' }]"
          >
            <span class="form-label"
              >2. ข้อมูลเจ้าของ<span class="text-red-500">*</span></span
            >
            <el-input
              placeholder="ชื่อ - นามสกุล"
              v-model="form.owner_name"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="owner_phone"
            :rules="[{ required: true, message: 'telephone is required' }]"
          >
            <el-input
              type="tel"
              placeholder="เบอร์โทรศัพท์"
              v-model="form.owner_phone"
            ></el-input>
          </el-form-item>

          <el-form-item
            prop="pet_name"
            :rules="[{ required: true, message: 'name pet is required' }]"
          >
            <span class="form-label"
              >3. ข้อมูลสัตว์เลี้ยง<span class="text-red-500">*</span></span
            >
            <el-input placeholder="ชื่อ" v-model="form.pet_name"></el-input>
          </el-form-item>
          <el-form-item
            prop="pet_type"
            :rules="[{ required: true, message: 'type pet is required' }]"
          >
            <el-select
              v-model="form.pet_type"
              placeholder="ชนิด"
              style="width: 100%"
            >
              <el-option
                v-for="item in listTypes"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>

      <div class="type-select grid grid-cols-2 gap-2 mt-4">
        <div class="flex">
          <el-button class="grow" @click="$router.go(-1)">
            <div class="flex">
              <div>
                <i class="el-icon-arrow-left"></i>
              </div>
              <div class="grow">
                <span class="ml-2"> กลับ</span>
              </div>
            </div>
          </el-button>
        </div>
        <div class="flex">
          <el-button class="grow" type="primary" @click="validateForm">
            <div class="flex">
              <div class="grow">
                <span class="ml-2"> เลือกกิจกรรมนัด</span>
              </div>
              <div>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  data() {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return {
      form: {
        date: date.toISOString().substring(0, 10),
        time: null, //new Date().toISOString().substring(11, 16),
        owner_name: null,
        owner_phone: null,
        pet_name: null,
        pet_type: null,
      },
      pickerDateOptions: {
        disabledDate(time) {
          date.setDate(date.getDate() + 1);
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          return time.getTime() < date;
        },
      },
    };
  },
  computed: {
    hospital: get("requestAppointment/hospital"),
    listTypes: get("pet/types"),
    isAuthenticated: get("auth/isAuthenticated"),
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push({ name: "request-appointment-pets" });
    }
    this.fetchListType();
  },
  methods: {
    async fetchListType() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("pet/listTypes");
      } catch (e) {
        await this.$store.dispatch("ui/showFetchErrorMsg");
      } finally {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      }
    },
    validateForm() {
      this.$refs.FormAppOwner.validate((valid) => {
        if (valid) {
          this.$store.commit(
            "requestAppointment/SET_APPOINTMENT_TIME",
            this.form.time
          );
          this.$store.commit(
            "requestAppointment/SET_APPOINTMENT_DATE",
            this.form.date
          );

          this.$store.commit("requestAppointment/SET_FORM_NO_LOGIN", {
            ...this.form,
            pet_type_desc: this.listTypes.find(
              (it) => it.id === this.form.pet_type
            ),
          });
          this.$router.push({
            name: "request-appointment-activities",
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.requestAppointmentOwner {
}
</style>