<template>
  <div class="pet-message-timeline">
    <el-timeline v-if="messages.length > 0">
      <el-timeline-item></el-timeline-item>
      <el-timeline-item :class="`${msg.type}`" v-for="(msg, index) in messages" :key="index">
        <MessageItem :msg="msg" @click="navigateToDetail" />
      </el-timeline-item>
      <el-timeline-item></el-timeline-item>
    </el-timeline>
    <div class="no-item" v-else-if="type != 'active'">
      <div class="grid flex-col justify-items-center">
        <img width="350px" src="@/assets/images/no-result/no-result-04.png" class="responsive-img" />
        <div class="text-gray-400 text-xl">ไม่มีรายการบันทึก</div>
      </div>
    </div>
    <div class="load-more-container" v-if="currentPage < totalPages">
      <button class="btn btn-small btn-primary" @click="loadMore">Load more...</button>
    </div>
  </div>
</template>

<script>
import MessageItem from "./MessageItem";

export default {
  components: {
    MessageItem
  },
  props: ["petHospitalId", "isOwner", "isPublic"],
  data() {
    return {
      id: this.petHospitalId,
      messages: [],
      currentPage: 1,
      totalPages: 1
    };
  },
  methods: {
    navigateToDetail(type, id) {
      this.$router.open({
        name: 'hospitalMessageDetail',
        params: { type, id },
      });
    },
    loadMore() {
      this.fetchMessages();
    },
    async fetchMessages() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        var response;

        let query = {};
        if (this.currentPage < this.totalPages) {
          this.currentPage += 1;
        }
        query["page"] = this.currentPage;

        if (this.isOwner) {
          response = await this.$store.dispatch(
            "owner/listPetTimelineMessages",
            { query, params: { petId: this.id } }
          );
        } else if (this.isPublic) {
          response = await this.$store.dispatch(
            "pet/listPublicTimelineMessages",
            { query, params: { code: this.id } }
          );
        } else {
          response = await this.$store.dispatch("pet/listTimelineMessages", {
            query, params: { petHospitalId: this.id }
          });
        }

        if (this.currentPage > 1) {
          this.messages = [...this.messages, ...response.results];
        } else {
          this.messages = response.results;
        }
        this.totalPages = Math.ceil(response.count / 10);

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      }
    }
  },

  async mounted() {
    this.fetchMessages();
  }
};
</script>

<style lang="scss">
.pet-message-timeline {
  .el-timeline {
    margin-left: -20px;
  }

  .el-timeline-item {
    padding-left: 20px;

    &:first-child {
      .el-timeline-item__node--normal {
        display: none;
      }
    }

    &:last-child {
      .el-timeline-item__node--normal {
        display: none;
      }

      padding: 0px;
    }

    .el-timeline-item__node--normal {
      left: -12px;
      width: 34px;
      height: 34px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .el-timeline-item__tail {
      border-width: 4px;
    }

    &.broadcast_broadcast {
      .el-timeline-item__node--normal {
        background-image: url("../../assets/icons/icon-broadcast.png");
      }
    }

    &.broadcast_systembroadcast {
      .el-timeline-item__node--normal {
        background-image: url("../../assets/icons/icon-vremind-broadcast.png");
      }
    }

    &.broadcast_supplierbroadcast {
      .el-timeline-item__node--normal {
        background-image: url("../../assets/icons/icon-brand-broadcast.png");
      }
    }

    &.message_message,
    &.article_article {
      .el-timeline-item__node--normal {
        background-image: url("../../assets/icons/icon-direct-message.png");
      }
    }
  }

  .load-more-container {
    text-align: center;
    padding: 0 0 20px 0;
  }
}
</style>