import RequestAppointmentPage from '@/pages/requestAppointment/RequestAppointmentPage.vue'
import RequestAppointmentPetsPage from '@/pages/requestAppointment/RequestAppointmentPetsPage.vue'
import RequestAppointmentActivitiesPage from '@/pages/requestAppointment/RequestAppointmentActivitiesPage.vue'
import RequestAppointmentLogin from '@/pages/requestAppointment/RequestAppointmentLogin.vue'
import RequestAppointmentOwner from '@/pages/requestAppointment/RequestAppointmentOwner.vue'

const routes = [
  {
    path: '/request-appointment',
    name: 'request-appointment',
    component: RequestAppointmentPage,
    meta: {
      requiresAuth: false,
      permissions: ['pet_owner']
    }
  },
  {
    path: '/request-appointment-pets',
    name: 'request-appointment-pets',
    component: RequestAppointmentPetsPage,
    meta: {
      requiresAuth: false,
      permissions: ['pet_owner']
    },
  },
  {
    path: '/request-appointment-activities',
    name: 'request-appointment-activities',
    component: RequestAppointmentActivitiesPage,
    meta: {
      requiresAuth: false,
      permissions: ['pet_owner']
    },
  },
  {
    path: '/request-appointment/:hospital_id',
    name: 'requestAppointmentLogin',
    component: RequestAppointmentLogin,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/request-appointment-owner',
    name: 'RequestAppointmentOwner',
    component: RequestAppointmentOwner,
    meta: {
      requiresAuth: false,
    },
  }
]

export default routes