import * as vuexHelper from "../helpers/vuexHelper";
import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
    hospitals: [],
    hospital: null,
    activities: [],
    selectedHospital: null,
    selectedPet: null,
    appointmentDate: null,
    appointmentTime: null,
    channel: 'website',

    //not login
    formNoLogin: {
      pet_name: null,
      pet_type: null,
      owner_name: null,
      owner_phone: null,
    }
   
  },
  getters: {
    hospital: state => state.hospital,
  },
  asyncActions: [
    {
			name: 'listHospitals',
			method: 'get',
      url: endpoints.hospitalSearch,
      setState: 'hospitals'
    },
    {
      name: "getHospital",
      method: "get",
      url: ({ id }) => `${endpoints.HOSPITAL_LIST}${id}/`,
      setState: "hospital",
      before(payload, { commit }) {
        commit('SET_HOSPITAL', null)
      }
    },
    {
      name: "listActivities",
      method: "get",
      url: endpoints.activityRequestAppointment,
      setState: "activities",
      before(payload, { commit }) {
        commit('SET_ACTIVITIES', null)
      }
    },
    {
      name: 'saveReuestAppointment',
      method: 'post',
      url: () => `${endpoints.appointments}requests/`,
    },
    {
      name: 'getListRequestAppointmentByPet',
      method: 'get',
      url: () => `${endpoints.appointments}requests/`,
    },
    {
      name: "getRequestAppointmentById",
      method: "get",
      url: ({ id }) => `${endpoints.appointments}requests/${id}/`,
    },
  ]
}
export default vuexHelper.createStoreModule(config);