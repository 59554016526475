<template>
  <div class="layout-container">
    <PlainNavbar />

    <div class="content">
      <h1 class="header-title">Appointment Details</h1>
      <ApptDetailPublic
        :appt="markdoneData"
        :apptState="apptState"
        :qrcode="qrcode"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import PlainNavbar from "@/components/navigation/PlainNavbar";
import ApptDetailPublic from "@/components/appointment/ApptDetailPublic";

export default {
  components: {
    PlainNavbar,
    ApptDetailPublic,
  },
  data() {
    return {
      hospital: null
    };
  },
  computed: {
    ...mapState({
      appt: (state) => state.appointment.selected,
    }),
    ...mapGetters({
      apptState: "appointment/apptState",
    }),
    markdoneData() {
      if (this.appt) {
        const markdoneItems = this.appt?.timeline?.filter(
          (item) => item.type === "done" || item.type === "edit_markdone"
        );
        if (markdoneItems?.length > 0) {
          // const markdoneData =
          //   markdoneItems[markdoneItems.length - 1].detail.markdone
          const markdoneData = markdoneItems[markdoneItems?.length - 1];
          return {
            hospital: typeof this.appt.hospital === "object" ?  this.appt.hospital : this.hospital,
            ...this.appt,
            done_date_time: markdoneData.datetime,
          };
        } else {
          return {...this.appt, hospital: typeof this.appt.hospital === "object" ?  this.appt.hospital : this.hospital };
        }
      }
      return null;
    },
    qrcode() {
      if (this.appt) {
        const protocol = window.location.protocol;
        const slashes = protocol.concat("//");
        let host = slashes.concat(window.location.hostname);
        const port = window.location.port;
        if (port) {
          host = host.concat(":" + port);
        }
        const url = host.concat(`/public/appointment/${this.appt.id}`);
        return url;
      }
      return "";
    },
  },
  methods: {
    async fetchData(id) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);

        if (this.$route.query.type === "requestApp") {
          const requestApp = await this.$store.dispatch(
            "appointment/getRequestAppointmentById",
            {
              params: { id: id },
            }
          );

          const hospital = await this.$store.dispatch(
            "hospital/getHospitalsById",
            {
              params: { id: requestApp.hospital },
            }
          );
          if (hospital) {
            this.hospital = hospital;
            console.log("🚀 ~ file: ApptDetailPublicPage.vue:92 ~ fetchData ~  this.hospital:",  this.hospital)
          }
        } else {
          await this.$store.dispatch("appointment/getAppointment", {
            params: { id: id },
          });
        }
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorWithReloadMsg");
      }
    },
  },
  created() {
    const id = this.$route.params.id;
    const user_type = localStorage.getItem("user_type");
    if (user_type == "hospital_admin") {
      this.$router.replace({ name: "appointment-detail", params: { id: id } });
    } else {
      this.fetchData(id);
    }
  },
};
</script>

<style lang="scss" scoped>
.layout-container {
  position: relative;
  background-color: $gray-background;
  // width: 100%;
  // height: 100%;
}
.content {
  margin: 0 auto;

  padding: 10px 0 20px;
  max-width: 320px;
}
.header-title {
  font-size: 20px;
  text-align: center;
}

@page {
  size: 80mm 11in;
  margin: 0;
}

@media print {
  .header-title {
    display: none;
  }
  .navbar {
    display: none;
  }
  .layout-container {
    background: #fff;
  }
  .content {
    padding: 0;
  }
}
</style>
