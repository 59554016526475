<template>
  <div class="pet-appointment-timeline">
    <div v-if="appointmentsNotComplete.length > 0">
      <PetAppointmentTimelineItem
        :items="appointmentsNotComplete"
        :showPetInfo="showPetInfo"
        :requestFlag="requestFlag"
      />
    </div>
    <div
      class="load-more-container"
      v-if="
        appointmentsNotComplete.length &&
        currentPageNotComplete < totalPagesNotComplete
      "
      style="margin-bottom: 60px"
    >
      <button
        class="btn btn-small btn-primary"
        @click="loadMore('not-complete')"
      >
        Load more...
      </button>
    </div>
    <div v-if="appointmentsComplete.length > 0">
      <el-collapse
        class="appointment-history-collapse"
        style="margin-bottom: 60px"
      >
        <el-collapse-item name="1">
          <template slot="title">
            <div class="appointment-history-collapse-title">
              ประวัติการนัดหมาย
            </div>
          </template>
          <PetAppointmentTimelineItem
            :items="appointmentsComplete"
            :showPetInfo="showPetInfo"
            :requestFlag="requestFlag"
          />
          <div
            class="load-more-container"
            v-if="appointmentsComplete.length && currentPage < totalPages"
            style="margin-bottom: 60px"
          >
            <button
              class="btn btn-small btn-primary"
              @click="loadMore('complete')"
            >
              Load more...
            </button>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <!-- <div
      class="no-item"
      v-if="!appointmentsNotComplete.length && !appointmentsComplete.length"
    >
      <div class="grid flex-col justify-items-center">
        <img
          width="350px"
          src="@/assets/images/no-result/no-result-04.png"
          class="responsive-img"
        />
        <div class="text-gray-400 text-xl">ไม่มีรายการบันทึก</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import _ from "lodash/";
import PetAppointmentTimelineItem from "./PetAppointmentTimelineItem.vue";
export default {
  // viewer : hospital, owner, public
  // viewIn: petPage, ownerPage
  // type: active, ''
  components: {
    PetAppointmentTimelineItem,
  },
  props: ["petHospitalId", "viewer", "viewIn", "type", "showPetInfo", 'requestFlag'],
  data() {
    return {
      id: this.petHospitalId,
      appointments: [],
      appointmentsNotComplete: [],
      appointmentsComplete: [],
      currentPageNotComplete: 1,
      totalPagesNotComplete: 1,
      currentPage: 1,
      totalPages: 1,
    };
  },
  methods: {
    loadMore(group) {
      this.fetchAppointments(group);
    },
    async fetchAppointments(group) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);

        let query = {};
        var response;
        if (group === "not-complete") {
          if (this.currentPageNotComplete < this.totalPagesNotComplete) {
            this.currentPageNotComplete += 1;
          }
          query["page"] = this.currentPageNotComplete;
        } else {
          if (this.currentPage < this.totalPages) {
            this.currentPage += 1;
          }
          query["page"] = this.currentPage;
        }

        if (this.viewer == "hospital") {
          if (this.viewIn == "ownerPage") {
            response = await this.$store.dispatch(
              "owner/getOwnerAppointmentTimeline",
              { query, params: { id: this.id } }
            );
          } else if (this.viewIn == "petPage" && this.type == "active") {
            response = await this.$store.dispatch(
              "pet/listAppointmentsActiveTimeline",
              {
                query,
                params: { petHospitalId: this.id },
              }
            );
          } else if (this.viewIn == "petPage") {
            response = await this.$store.dispatch("pet/listAppointments", {
              query,
              params: { petHospitalId: this.id },
            });
          }
        } else if (this.viewer == "owner") {
          if (this.viewIn == "ownerPage") {
            response = await this.$store.dispatch(
              "owner/getAppoinmentTimeline",
              { query }
            );
          } else if (this.viewIn == "petPage" && this.type == "active") {
            response = await this.$store.dispatch(
              "owner/listPetTimelineAppointmentsActive",
              {
                query,
                params: { petId: this.id },
              }
            );
          } else if (this.viewIn == "petPage" && this.requestFlag) {
            response = await this.$store.dispatch(
              "requestAppointment/getListRequestAppointmentByPet",
              {
                query: {
                  pet: this.id
                }
              }
            );
          } else if (this.viewIn == "petPage") {
            response = await this.$store.dispatch(
              "owner/listPetTimelineAppointments",
              {
                query,
                params: { petId: this.id },
              }
            );
          } 
        } else if (this.viewer == "public") {
          if (this.viewIn == "petPage" && this.type == "active") {
            response = await this.$store.dispatch(
              "pet/listPublicTimelineAppointmentActive",
              {
                query,
                params: { code: this.id },
              }
            );
          } else if (this.viewIn == "petPage") {
            response = await this.$store.dispatch(
              "pet/listPublicTimelineAppointment",
              {
                query,
                params: { code: this.id },
              }
            );
          }
        }

        const listGroupOne = ["no_response", "comfirm", "accept", 'pending'];
        const listGroupTwo = ["cancel", "done", "postpone", "no_show"];

        if (this.type !== "active") {
          if (this.currentPage > 1) {
            // this.appointments = [...this.appointments, ...response.results];

            if (group === "not-complete") {
              this.appointmentsNotComplete = [
                ...this.appointmentsNotComplete,
                ..._.filter(response.results, (it) =>
                  _.includes(listGroupOne, it.status)
                ),
              ];
            } else {
              this.appointmentsComplete = [
                ...this.appointmentsComplete,
                ..._.filter(response.results, (it) =>
                  _.includes(listGroupTwo, it.status)
                ),
              ];
            }
          } else {
            this.appointmentsNotComplete = _.filter(response.results, (it) =>
              _.includes(listGroupOne, it.status)
            );
            this.appointmentsComplete = _.filter(response.results, (it) =>
              _.includes(listGroupTwo, it.status)
            );
          }
          if (group === "not-complete") {
            this.totalPagesNotComplete = Math.ceil(response.count / 10);
          } else {
            this.totalPages = Math.ceil(response.count / 10);
          }
        } else {
          this.appointmentsNotComplete = _.filter(response.results, (it) =>
            _.includes(listGroupOne, it.status)
          );
          this.appointmentsComplete = _.filter(response.results, (it) =>
            _.includes(listGroupTwo, it.status)
          );
        }

        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      }
    },
  },

  async mounted() {
    this.fetchAppointments("not-complete");
    this.fetchAppointments("complete");
  },
};
</script>
