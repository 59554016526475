<template>
  <div style="text-align: start">
    <el-row type="flex" justify="center">
      <el-col :span="6" align="center">
        <div class="my-2" v-if="hospital && hospital?.logo">
          <div>
            <el-image :src="hospital?.logo?.large" width="100px" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="6" align="center">
        {{ hospital?.name }}
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <small>
          วันที่สร้าง : {{ requestAppointment?.created | toThaiDate }}
          {{ requestAppointment?.created | toHHMM }}</small
        >
      </el-col>
    </el-row>

    <el-divider class="my-0"></el-divider>

    <el-row class="my-4">
      <el-col class="text-gray">1. วันเวลาที่นัด</el-col>
      <el-col :offset="2">
        {{ requestAppointment?.date_time | toThaiDate }}
        <span class="ml-4"
          >{{ requestAppointment?.date_time | toHHMM }} น.</span
        >
      </el-col>
    </el-row>

    <el-row class="my-4" v-show="requestAppointment?.is_anonymous">
      <el-col class="text-gray">2. ข้อมูลเจ้าของ </el-col>
      <el-col :span="22" :offset="2">
        <p class="text-clip overflow-hidden">
          {{ requestAppointment?.owner?.profile?.firstname }}
          {{ requestAppointment?.owner?.profile?.lastname }}
        </p>
        <p class="text-clip overflow-hidden">
          {{ requestAppointment?.owner?.phone }}
        </p>
      </el-col>
    </el-row>

    <el-row class="my-4">
      <el-col class="text-gray"
        >{{ requestAppointment?.is_anonymous ? 3 : 2 }}.
        ข้อมูลสัตว์เลี้ยง</el-col
      >
      <el-col :offset="2" v-if="requestAppointment?.is_anonymous">
        <p class="text-clip overflow-hidden">
          {{ requestAppointment?.pet?.name }}
        </p>
        <p class="text-clip overflow-hidden">
          {{ requestAppointment?.pet?.type?.name }}
        </p>
      </el-col>
      <el-col :offset="2" v-else>
        <el-row :gutter="20">
          <el-col :span="2">
            <el-avatar
              :size="40"
              fit="cover"
              :src="requestAppointment?.pet?.image?.small"
            ></el-avatar
          ></el-col>
          <el-col :span="4" :offset="1">
            {{ requestAppointment?.pet?.name }}
          </el-col>
          <el-col :span="6" :offset="1">
            <img
              width="30px"
              v-if="requestAppointment?.pet?.type?.key == 'DOG'"
              src="@/assets/icons/icon-dog.png"
            />
            <img
              width="30px"
              v-if="requestAppointment?.pet?.type?.key == 'CAT'"
              src="@/assets/icons/icon-cat.png"
            />
            <span
              v-if="
                requestAppointment?.pet?.type?.key != 'DOG' &&
                requestAppointment?.pet?.type?.key != 'CAT'
              "
              >{{ requestAppointment?.pet?.type?.name.split("/")[0] }}</span
            ></el-col
          >
        </el-row>
      </el-col>
    </el-row>

    <el-row class="my-4">
      <el-col class=" ">
        <div class="flex justify-between">
          <div class="text-gray">
            {{ requestAppointment?.is_anonymous ? 4 : 3 }}.
            เลือกกิจกรรมนัด*<span class="text-red-500"></span>
          </div>
        </div>
      </el-col>
      <el-col :span="22" :offset="2">
        <el-row
          type="flex"
          class="row-bg"
          justify="space-between"
          v-for="(it, id) in requestAppointment?.activities"
          :key="id"
        >
          <el-col :span="18">{{ it?.name }}</el-col>
          <el-col :span="6" align="end">{{ it?.price || "-" }} บาท</el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row class="my-4">
      <el-col class="text-gray">
        {{ requestAppointment?.is_anonymous ? 5 : 4 }}. ข้อมูลเพิ่มเติม
      </el-col>
      <el-col :span="22" :offset="2">
        <p class="text-clip overflow-hidden">
          {{ requestAppointment?.note || "-" }}
        </p>
      </el-col>
    </el-row>
    <el-divider class="my-0"></el-divider>
    <small>*เจ้าหน้าที่กรุณาติดต่อกลับภายใน 24 ชั่วโมง</small>

    <el-row class="mt-4">
      <el-col>
        สถานะ :
        <el-tag :type="checkStatus(requestAppointment?.status)" effect="dark">{{
          requestAppointment?.status
        }}</el-tag>
      </el-col>
    </el-row>

    <el-row
      class="mt-4"
      type="flex"
      justify="center"
      v-show="!requestAppointment?.is_anonymous"
    >
      <el-col align="center">
        <el-button
          v-show="requestAppointment?.status === 'accept'"
          type="primary"
          @click="
            $router.push({
              name: 'public-appointment-detail',
              params: { id: requestAppointment?.id },
              query: { type: 'requestApp'}
            })
          "
          >ดูรายละเอียดการนัดหมาย</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>


<script>
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      hospital: {},
      requestAppointment: {},
    };
  },
  watch: {
    id(val) {
      console.log("🚀 ~ file: requestAppDetail.vue:184 ~ id ~ val:", val)
      if (val) {
       console.log("🚀 ~ file: requestAppDetail.vue:185 ~ item ~ val:", val)
       this.fetchData()
      }
    },
  },
  mounted() {},
  methods: {
    async fetchData() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);

        const requestApp = await this.$store.dispatch(
          "requestAppointment/getRequestAppointmentById",
          {
            params: { id: this.id },
          }
        );
        this.requestAppointment = requestApp;

        const hospital = await this.$store.dispatch(
          "hospital/getHospitalsById",
          {
            params: { id: requestApp.hospital },
          }
        );
        if (hospital) {
          this.hospital = hospital;
        }
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      } finally {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      }
    },
    displayDateFormat(date) {
      return this.$options.filters.toThaiDate(date);
    },
    checkStatus(type) {
      switch (type) {
        case "pending":
          return "primary";
        case "request":
          return "warning";
        case "cancel":
          return "info";
        case "accept":
          return "success";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-gray {
  color: #8e8e93;
}
</style>