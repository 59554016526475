import PetOwnerRegister from '@/pages/owner/PetOwnerRegister'
import PetOwnerRegisterOTP from '@/pages/owner/PetOwnerRegisterOTP'
import PetOwnerLogin from '@/pages/owner/PetOwnerLogin'
import PetOwnerForgetPassword from '@/pages/owner/PetOwnerForgetPassword'
import PetOwnerForgetPasswordOTP from '@/pages/owner/PetOwnerForgetPasswordOTP'
import PetOwnerForgetPasswordRecovery from '@/pages/owner/PetOwnerForgetPasswordRecovery'
import PetOwnerGeneralInfo from '@/pages/owner/PetOwnerGeneralInfo'
import PetOwnerMapLocation from '@/pages/owner/PetOwnerMapLocation'
import PetOwnerAddress from '@/pages/owner/PetOwnerAddress'
import PetOwnerRegisterSocial from '@/pages/owner/PetOwnerRegisterSocial'
import PetOwnerHome from '@/pages/owner/PetOwnerHome'
import PetProfile from '@/pages/owner/PetProfile'
import PetGeneralInfo from '@/pages/owner/PetGeneralInfo'
import PetHealth from '@/pages/owner/PetHealth'
import PetHealthPreview from '@/pages/owner/PetHealthPreview'
import PetInsurances from '@/pages/owner/PetInsurances'
import PetInsurancesClaim from '@/pages/owner/PetInsurancesClaim'
import PetOwnerSelectPet from '@/pages/owner/PetOwnerSelectPet'
import PetHealthDetail from '@/pages/owner/PetHealthDetail'
import HospitalConnection from '@/pages/hospital/Connection.vue'
import SocialWebHookPage from '@/pages/owner/SocialWebHookPage'

const routes = [
  {
    path: '',
    name: 'petOwnerLogin',
    component: PetOwnerLogin,
    meta: {
      name: "PetOwnerLogin",
      requiresAuth: false
    },
    beforeEnter: (to, from, next) => {
      const user_type = localStorage.getItem("user_type");
      const token = localStorage.getItem("token");
      if (token != null) {
        if (user_type == "hospital_admin") {
          // next({
          //   name: "hospitalHome"
          // });
        } else if (user_type == "pet_owner") {
          next({
            name: "petOwnerHome"
          });
        }
      } else {
        next();
      }
    }
  },
  {
    path: 'register',
    name: 'petOwnerRegister',
    component: PetOwnerRegister,
    meta: {
      name: "PetOwnerRegister",
      requiresAuth: false
    },
    beforeEnter: (to, from, next) => {
      const user_type = localStorage.getItem("user_type");
      const token = localStorage.getItem("token");
      if (token != null) {
        if (user_type == "hospital_admin") {
          // next({
          //   name: "hospitalHome"
          // });
        } else if (user_type == "pet_owner") {
          next({
            name: "petOwnerHome"
          });
        }
      } else {
        next();
      }
    }
  },
  {
    path: 'register/verify',
    name: 'petOwnerRegisterOTP',
    component: PetOwnerRegisterOTP,
    meta: {
      name: "PetOwnerRegisterOTP",
      requiresAuth: false
    }
  },
  {
    path: 'forget-password',
    name: 'petOwnerForgetPassword',
    component: PetOwnerForgetPassword,
    meta: {
      name: "PetOwnerForgetPassword",
      requiresAuth: false
    },
    beforeEnter: (to, from, next) => {
      const user_type = localStorage.getItem("user_type");
      const token = localStorage.getItem("token");
      if (token != null && user_type == "pet_owner") {
        next({
          name: "petOwnerHome"
        });
      } else {
        next();
      }
    }
  },
  {
    path: 'forget-password/verify',
    name: 'petOwnerForgetPasswordOTP',
    component: PetOwnerForgetPasswordOTP,
    meta: {
      name: "PetOwnerForgetPasswordOTP",
      requiresAuth: false
    }
  },
  {
    path: 'forget-password/recovery',
    name: 'petOwnerForgetPasswordRecovery',
    component: PetOwnerForgetPasswordRecovery,
    meta: {
      name: "PetOwnerForgetPasswordRecovery",
      requiresAuth: false
    }
  },
  {
    path: 'general-info',
    name: 'petOwnerGeneralInfo',
    component: PetOwnerGeneralInfo,
    meta: {
      name: "PetOwnerGeneralInfo",
      requiresAuth: true,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'map-location',
    name: 'petOwnerMapLocation',
    component: PetOwnerMapLocation,
    meta: {
      name: "PetOwnerMapLocation",
      requiresAuth: true,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'address',
    name: 'petOwnerAddress',
    component: PetOwnerAddress,
    meta: {
      name: "PetOwnerAddress",
      requiresAuth: true,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'social',
    name: 'petOwnerRegisterSocial',
    component: PetOwnerRegisterSocial,
    meta: {
      name: "PetOwnerRegisterSocial",
      requiresAuth: true,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'home',
    name: 'petOwnerHome',
    component: PetOwnerHome,
    meta: {
      name: "PetOwnerHome",
      requiresAuth: true,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'pet/general-info',
    name: 'petGeneralInfo',
    component: PetGeneralInfo,
    meta: {
      requiresAuth: true,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'pet/general-info/:id/',
    name: 'petGeneralInfoEdit',
    component: PetGeneralInfo,
    meta: {
      requiresAuth: true,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'pet/health',
    name: 'petHealth',
    component: PetHealth,
    meta: {
      requiresAuth: true,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'pet/selectPet',
    name: 'petOwnerSelectPet',
    component: PetOwnerSelectPet,
    meta: {
      requiresAuth: true,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'pet/health-record',
    name: 'petHealthRecoard',
    component: PetHealthPreview,
    meta: {
      requiresAuth: false,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'pet/health-record/:id',
    name: 'petHealthRecoard',
    component: PetHealthPreview,
    meta: {
      requiresAuth: false,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'health-record/:id',
    name: 'petHealthRecoard',
    component: PetHealthPreview,
    meta: {
      requiresAuth: false,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'pet/insurances',
    name: 'petInsurances',
    component: PetInsurances,
    meta: {
      requiresAuth: true,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'pet/insurancesClaim',
    name: 'petInsurancesClaim',
    component: PetInsurancesClaim,
    meta: {
      requiresAuth: true,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'pet/health-detail',
    name: 'petHealthDetail',
    component: PetHealthDetail,
    meta: {
      requiresAuth: true,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'pet/health-detail/:id/',
    name: 'petHealthDetailEdit',
    component: PetHealthDetail,
    meta: {
      requiresAuth: true,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'pet/:id/',
    name: 'petProfile',
    component: PetProfile,
    meta: {
      name: "PetProfile",
      requiresAuth: true,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'hospital/connection',
    name: 'hospitalConnection',
    component: HospitalConnection,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: 'me/:social',
    name: 'socialWebHookPage',
    component: SocialWebHookPage,
    meta: {
      requiresAuth: true,
      permissions: ['pet_owner']
    }
  },
  {
    path: 'r/:phone/',
    name: 'invitePage',
    component: PetOwnerForgetPasswordRecovery,
    meta: {
      requiresAuth: false
    }
  },
]

export default routes
