<template>
  <div class="pet-appointment-timeline">
    <el-timeline>
      <el-timeline-item></el-timeline-item>
      <el-timeline-item v-for="(it, index) in items" :key="index">
        <el-card>
          <div class="contain">
            <div class="detail">
              <div class="key" style="text-transform: capitalize">
                {{ it.type }}
              </div>
              <div class="time">
                <span>{{ it.date_time | toThaiDate }}</span>
                <span>
                  <template v-if="!it.is_no_time"
                    >{{ it.date_time | toHHMM }} น.</template
                  >
                  <template v-else>ไม่ระบุเวลา</template>
                </span>
              </div>
            </div>
            <div class="detail">
              <div class="pet-info" v-if="showPetInfo">
                <img
                  class="pet-profile"
                  v-if="it.pet.image && it.pet.image.small"
                  :src="it.pet.image.small"
                />
                <img
                  class="pet-profile"
                  v-else
                  src="@/assets/icons/icon-camera-plus.png"
                />
                <div class="detail" style="margin-left: 50px">
                  <div class="value">{{ it.pet.name }}</div>
                  <div class="key">{{ it.pet.type.name }}</div>
                </div>
              </div>
              <div class="key" v-if="it.hospital.name">
                {{ it.hospital.name }}
              </div>
              <div class="key" v-if="it.vet">
                Veterinary :
                {{ `${it.vet.title}${it.vet.first_name} ${it.vet.last_name}` }}
              </div>
              <div class="key" v-else>Veterinary : ไม่ระบุ</div>
              <div class="value">
                Activities :
                <span v-for="(activity, index) in it.activities" :key="index">
                  {{ activity.name
                  }}<span v-if="index != it.activities.length - 1">, </span>
                </span>
              </div>
            </div>
            <div :class="`status ${it.status}`">
              <span>&#9900;</span>
              <div class="detail" v-if="it">
                {{ it.status.replace("_", " ").toUpperCase() }}
              </div>
            </div>
          </div>
          <el-button
            v-if="!requestFlag"
            class="action-btn b1"
            @click="goApptDetail(it.id)"
            >ดูรายละเอียด
          </el-button>
          <el-button v-else class="action-btn b1" @click="showDetail(it.id)"
            >ดูรายละเอียด
          </el-button>
        </el-card>
      </el-timeline-item>
      <el-timeline-item></el-timeline-item>
    </el-timeline>

    <el-dialog custom-class="el-dialog-detail" :visible.sync="dialogDetail">
      <RequestAppDetail :id="selectItem" />
    </el-dialog>
  </div>
</template>

<script>
import RequestAppDetail from "@/components/requestAppointment/requestAppDetail.vue";
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    showPetInfo: {
      type: Boolean,
      default: false,
    },
    requestFlag: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RequestAppDetail,
  },
  data() {
    return {
      dialogDetail: false,
      selectItem: "",
    };
  },
  methods: {
    showDetail(item) {
      this.dialogDetail = true;
      this.$nextTick(() => {
        this.selectItem = item;
      });
    },
    goApptDetail(apptId) {
      const route =
        localStorage.getItem("user_type") == "hospital_admin"
          ? "appointment-detail"
          : "public-appointment-detail";
      this.$router.push({ name: route, params: { id: apptId } });
    },
  },
};
</script>


<style lang="scss" scoped>
.pet-info {
  vertical-align: middle;
  height: 42px;

  div {
    top: 0;
    width: 200px;

    .detail {
      width: 200px;

      .key,
      .value {
        display: block;
      }
    }
  }

  img.pet-profile {
    float: left;
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
    border: solid 1px #979797;
  }
}

.no-item {
  font-family: default;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.7px;
  text-align: center;
  color: #8e8e93;
  margin: 72px 0px;
}

.detail {
  text-align: left;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0px;
  }

  .time {
    width: 100%;
    max-width: 269px;
    height: 30px;
    border-radius: 4px;
    color: white;
    background-color: #156f88;

    text-align: center;
    vertical-align: middle;
    line-height: 30px;

    span {
      &:first-child {
        float: left;
        margin-left: 8px;
      }

      &:last-child {
        float: right;
        margin-right: 8px;
      }
    }
  }

  .key {
    font-family: default;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    color: #8e8e93;
  }

  .value {
    font-family: default;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #030303;

    &.small {
      font-family: default;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #354052;
    }

    &.big {
      font-family: default;
      font-size: 18px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    &.line {
      border-bottom: 1px solid #e6eaee;
    }

    .sub {
      font-size: 16px;
      font-weight: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.status {
  line-height: 18px;
  position: absolute;
  top: 12px;
  right: 12px;

  @include mobile {
    top: 0;
    right: 0;
    position: relative;
    float: none;
  }

  display: inline-block;

  // width: 50px;
  span {
    display: inline;
    vertical-align: middle;
    margin-right: 6px;

    font-weight: bold;
    color: #02b0e1;
  }

  .detail {
    display: inline;
    vertical-align: middle;

    padding: 0px 8px 0px 8px;
    height: 18px;
    border-radius: 3px;
    background-color: #02b0e1;

    text-align: center;
    vertical-align: middle;
    line-height: 18px;

    font-family: default;
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  &.request_postpone {
    div {
      background: #ff4f4a;
    }

    span {
      color: #ff4f4a;
    }
  }

  &.no_show {
    div {
      background: #ff8800;
    }

    span {
      color: #ff8800;
    }
  }

  &.no_respond {
    div {
      background: #ffc300;
    }

    span {
      color: #ffc300;
    }
  }

  &.confirm {
    div {
      background: #7ed321;
    }

    span {
      color: #7ed321;
    }
  }

  &.done {
    div {
      background: #90d5e8;
    }

    span {
      color: #90d5e8;
    }
  }

  &.postpone {
    div {
      background: #a8aab7;
    }

    span {
      color: #a8aab7;
    }
  }

  &.terminate {
    div {
      background: #d8d8d8;
    }

    span {
      color: #d8d8d8;
    }
  }

  &.request {
    div {
      background: #ff8800;
    }

    span {
      color: #ff8800;
    }
  }
  &.pending {
    div {
      background: #02b0e1;
    }

    span {
      color: #02b0e1;
    }
  }
  &.cancel {
    div {
      background: #a8aab7;
    }

    span {
      color: #a8aab7;
    }
  }
  &.accept {
    div {
      background: #5b9f11;
    }

    span {
      color: #5b9f11;
    }
  }
}
</style>


<style lang="scss">
.pet-appointment-timeline {
  .el-timeline {
    padding: 0px 0px 0px 12px;
    margin: auto;
    overflow: hidden;
  }

  .el-timeline-item__wrapper {
    padding-left: 50px;
  }

  .el-timeline-item__tail {
    left: 3px;
    border-left: 4px solid #e4e7ed;
  }

  .el-timeline-item {
    .el-timeline-item__node--normal {
      left: -12px;
      width: 34px;
      height: 34px;
      background-image: url("../../assets/icons/icon-calendar2.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &:first-child {
      .el-timeline-item__node--normal {
        display: none;
      }
    }

    &:last-child {
      .el-timeline-item__node--normal {
        display: none;
      }

      padding: 0px;
    }

    // &:nth-last-child(2) {
    //   height: 300px;
    // }
  }
}
</style>

<style lang="scss">
.pet-appointment-timeline {
  .el-card__body {
    padding: 0px;
  }

  .el-dialog-detail {
    width: 30%;
  }

  @media (max-width: 768px) {
    .el-dialog-detail {
      width: 95%;
    }
  }
}

.load-more-container {
  text-align: center;
  padding: 0 0 20px 0;
}
</style>


<style lang="scss" scoped>
.el-button {
  img {
    width: 20px;
    height: 20px;
    object-fit: contain;

    display: inline;
    vertical-align: middle;
  }

  &.side-button {
    width: 100%;
    // margin-top: 5px;
    margin-bottom: 15px;
  }
}

.el-button {
  &.action-btn {
    color: #156f88;
    border-radius: 5px;
    border: none;

    &:not(:hover) {
      background-color: #edfbff;
    }

    &.b1 {
      border-radius: 0px;
      width: 100%;
    }
  }
}

.contain {
  padding: 12px;
}
</style>