<template>
  <div class="px-1">
    <span class="form-label"
      >รายละเอียด <span class="text-red-600">*</span>
    </span>

    <span v-if="items.length">
      <span v-for="(itemMain, index) in items" :key="index">
        <ValidationObserver ref="observer">
          <el-form-item>
            <div class="flex justify-between">
              <span class="Body">รายการที่ {{ index + 1 }} </span>

              <el-link
                :v-show="!flagEdit"
                :underline="false"
                type="danger"
                @click="reduceList(index)"
                ><i class="el-icon-error"></i> ลบรายการ
              </el-link>
            </div>
            <ValidationProvider v-slot="{ valid }" :rules="{ required: true }">
              <el-select
                :disabled="flagEdit"
                name="selectedCategory"
                placeholder="ชนิด/ยี่ห้อ"
                style="width: 100%; margin-top: 5px"
                v-model="itemMain.category"
                filterable
                @change="selectedCategory($event, index)"
              >
                <el-option
                  v-for="(item, index) in foodsList"
                  :key="index"
                  :label="item?.name"
                  :value="item?.id"
                >
                </el-option>
              </el-select>
              <span
                v-if="typeof valid === 'boolean' ? !valid : false"
                class="text-red-500"
              >
                * กรุณาเลือกรายการ
              </span>
            </ValidationProvider>
            <ValidationProvider v-slot="{ valid }" :rules="{ required: true }">
              <el-select
                :disabled="flagEdit"
                name="selectedProduct"
                placeholder="ผลิตภัณฑ์ที่ใช้"
                style="width: 100%; margin-top: 8px"
                v-model="itemMain.product"
                filterable
                @change="selectedProduct($event, index)"
              >
                <el-option
                  v-for="(item, index) in itemMain.productsList"
                  :key="index"
                  :label="item?.trade_name"
                  :value="item?.id"
                >
                </el-option>
              </el-select>
              <span
                v-if="typeof valid === 'boolean' ? !valid : false"
                class="text-red-500"
              >
                * กรุณาเลือกรายการ
              </span>
            </ValidationProvider>
          </el-form-item>
        </ValidationObserver>
      </span>
    </span>
    <el-form-item v-else>
      <p class="text-red-500">
        รายละเอียดมีไม่น้อยกว่า 1 รายการ กรุณาเพิ่มรายการ
      </p>
    </el-form-item>

    <el-row type="flex" justify="center" :v-show="!flagEdit">
      <el-col :span="6">
        <el-button type="primary" plain @click="increaseList"
          >เพิ่มรายการ <i class="el-icon-circle-plus"></i
        ></el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { get } from "vuex-pathify";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    emitData: {
      type: Boolean,
      default: false,
    },
    flagEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [
        {
          category: null,
          category_name: null,
          product: null,
          product_name: null,

          productsList: [],
        },
      ],
      foodsList: [],
    };
  },
  computed: {
    healthForm: get("healthRecord/healthForm"),
    flagInsert: get("healthRecord/flagInsert"),
  },
  watch: {
    async emitData() {
      let checkForm = [];
      for (const element of this.$refs.observer) {
        const isValid = await element.validate();
        if (isValid) {
          checkForm.push(true);
        } else {
          checkForm.push(false);
        }
      }

      const everyResult = checkForm.every((it) => it);

      if (!everyResult) return;

      this.$emit("resultData", this.items);
    },
  },
  mounted() {
    this.fetchData();
    if (this.flagEdit || this.flagInsert) {
      this.items = this.healthForm.food_items.map((it) => ({
        ...it,
        productsList: [{ id: it.product, trade_name: it.product_name }],
      }));
    }
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      const { results } = await this.$store.dispatch(
        "healthRecord/getListFoodCategory"
      );
      this.foodsList = results;
      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    },
    selectedCategory(event, index) {
      const item = this.foodsList.find((it) => it.id === event);
      this.items[index].category_name = item.name;
      this.items[index].productsList = item.products.sort((a, b) => {
        const nameA = a.trade_name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.trade_name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      this.items[index].product = null;
      this.$forceUpdate();
    },
    selectedProduct(event, index) {
      if (!event) return;
      const item = this.items[index].productsList.find((it) => it.id === event);

      this.items[index].product_name = item.trade_name;
    },
    increaseList() {
      this.items.push({
        category: null,
        product: null,
      });
    },
    reduceList(index) {
      this.items.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped></style>