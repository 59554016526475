<template>
  <div class="preventiveItem">
    <el-row class="mb-6 text-xs md:text-base">
      <p>สรุปผลจากการบันทึกป้องกันใน ระบบ *บันทึกสุขภาพ</p>
      <el-row class="flex gap-2">
        <div class="flex items-center">
          <span class="dot" :style="`background-color: ${color[0]};`"></span
          >&nbsp;ยังไม่เริ่มต้นบันทึก
        </div>
        <div class="flex items-center">
          <span class="dot" :style="`background-color: ${color[1]};`"></span
          >&nbsp;ปลอดภัย
        </div>
        <div class="flex items-center">
          <span class="dot" :style="`background-color: ${color[2]};`"></span
          >&nbsp;เริ่มมีความเสี่ยง
        </div>
        <div class="flex items-center">
          <span class="dot" :style="`background-color: ${color[3]};`"></span
          >&nbsp;เสี่ยงสูง
        </div>
      </el-row>
    </el-row>

    <el-card class="mt-4" v-show="vaccine.length">
      <div class="container p-2 px-5">
        <div class="grid justify-items-center my-2">
          <div class="text-xl">สถานะการฉีดวัคซีน</div>
        </div>
        <div class="min-h-10" v-for="(it, id) in vaccine" :key="id">
          <div class="flex">
            <div class="flex items-center">
              <span class="dot" :style="`background-color: ${it.color};`"></span
              >&nbsp;{{ it?.symtom }}
            </div>
          </div>
          <div class="flex items-top">
            <div class="flex-1">
              <div class="progress-bar ml-2" v-show="it?.countDate != 0">
                <span
                  :style="`
                  background-color: ${it.color};
                  width: ${it.barWidth}%`"
                ></span>
              </div>
            </div>
            <div class="flex-none w-7 ml-2 -mt-2">
              <p class="text-base leading-none" :style="`color: ${it.color}`">
                {{ it.countDate }}
              </p>
            </div>
          </div>
          <div class="grid justify-items-end" v-show="it?.countDate != 0">
            <p class="text-xs leading-none text-gray-400">
              {{ it.effectiveDate }}
            </p>
          </div>
        </div>
      </div>
      <div class="grid m-4">
        <el-button
          type="primary"
          class="w-full"
          plain
          @click="$router.push({ name: 'request-appointment' })"
        >
          ขอนัดหมายเพื่อฉีดวัคซีน
        </el-button>
      </div>
    </el-card>

    <el-card class="mt-4" v-show="parasite.length">
      <div class="container p-2 px-5">
        <div class="grid justify-items-center my-2">
          <div class="text-xl">สถานะการป้องกันปรสิต ภายนอก, ภายใน</div>
        </div>
        <div class="min-h-10" v-for="(it, id) in parasite" :key="id">
          <div class="flex">
            <div class="flex items-center">
              <span class="dot" :style="`background-color: ${it.color};`"></span
              >&nbsp;{{ it?.symtom }}
            </div>
          </div>
          <div class="flex items-top">
            <div class="flex-1">
              <div class="progress-bar ml-2" v-show="it?.countDate != 0">
                <span
                  :style="`
                  background-color: ${it.color};
                  width: ${it.barWidth}%`"
                ></span>
              </div>
            </div>
            <div class="flex-none w-7 ml-2 -mt-2">
              <p class="text-base leading-none" :style="`color: ${it.color}`">
                {{ it.countDate }}
              </p>
            </div>
          </div>
          <div class="grid justify-items-end" v-show="it?.countDate != 0">
            <p class="text-xs leading-none text-gray-400">
              {{ it.effectiveDate }}
            </p>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import moment from "moment";
export default {
  name: "preventiveItem",
  data() {
    return {
      color: ["#a8aab7", "#51b53c", "#fad369", "#d0021b"],
      vaccine: [],
      parasite: [],
    };
  },
  computed: {
    pet: get("owner/pet"),
  },
  mounted() {
    this.fetchData();
    // this.vaccine = this.vaccine
    // .map((it) => {
    //   let dateEff = this.showEffective(it?.last_date, it?.effective_days);

    //   return {
    //     ...it,
    //     color: !it.last_date ? this.color[0]  : this.checkEffectiveDay(this.countDownEffective(dateEff)),
    //     countDate: it?.effective_days
    //       ? this.countDownEffective(dateEff)
    //       : 0,
    //     barWidth: this.calaPercen(
    //       this.countDownEffective(dateEff),
    //       it?.effective_days
    //     ),
    //     effectiveDate: dateEff,
    //   };
    // })
    // .sort((itA, itB) => itA.countDate - itB.countDate);
  },
  methods: {
    formatProgressBar(percentage) {
      return percentage === 100 ? "Full" : `${percentage}`;
    },
    async fetchData() {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      try {
        const data = await this.$store.dispatch("pet/getPreventiveStatus", {
          params: { id: this.pet.id },
        });
        let tempItes = data.vaccine
          .map((it) => this.mapValue(it))
          .sort((itA, itB) => itA.countDate - itB.countDate);

        let countZero = tempItes.filter((it) => it.countDate === 0);
        let items = tempItes.filter((it) => it.countDate != 0);

        this.vaccine = [...items, ...countZero];

        let tempItesP = data.parasite
          .map((it) => this.mapValue(it))
          .sort((itA, itB) => itA.countDate - itB.countDate);
        let countZerP = tempItesP.filter((it) => it.countDate === 0);
        let itemsP = tempItesP.filter((it) => it.countDate != 0);
        this.parasite = [...itemsP, ...countZerP];
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      } finally {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      }
    },
    mapValue(item) {
      let dateEff = this.showEffective(item?.last_date, item?.effective_days);
      return {
        ...item,
        color: !item.last_date
          ? this.color[0]
          : this.checkEffectiveDay(this.countDownEffective(dateEff)),
        countDate: item?.effective_days ? this.countDownEffective(dateEff) : 0,
        barWidth: this.calaPercen(
          this.countDownEffective(dateEff),
          item?.effective_days
        ),
        effectiveDate: dateEff,
      };
    },
    showEffective(startdate, numDay) {
      if (!startdate || numDay === 0) return null;
      return moment(startdate, "YYYY-MM-DD")
        .add(numDay, "days")
        .format("YYYY-MM-DD");
    },
    countDownEffective(end) {
      if (!end) return 0;
      var currrentDate = moment();
      var endDate = moment(end, "YYYY-MM-DD");
      return endDate.diff(currrentDate, "days"); // 1
    },
    checkEffectiveDay(dayCount) {
      if (dayCount > 0) {
        return this.color[1];
      } else if (dayCount <= 0 && dayCount >= -30) {
        return this.color[2];
      } else {
        return this.color[3];
      }
    },
    calaPercen(left_days, dayCount) {
      if (left_days <= 0) {
        return 5;
      } else {
        return (left_days / dayCount) * 100;
      }
    },
  },
};
</script>

<style lang="scss" >
.preventiveItem {
  .progress-bar {
    height: 4px;
  }
  .progress-bar span {
    display: block;
    float: right;
    height: 100%;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
    -webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
    transition: width 0.4s ease-in-out;
  }

  .dot {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;
  }
}
</style>