<template>
  <ContentBox v-if="appt">
    <div class="section hospital-section">
      <Avatar :src="hospital.logo" :size="80"/>
      <h2 class="title">{{ hospital.name }}</h2>
    </div>
    <div v-if="pet" class="section pet-section">
      <PetProfile :pet="pet" :owner="appt.remind_to || appt.owner" />
    </div>
    <div class="section appointment-section">
      <h2 class="title">Appointment ID</h2>
      <div class="qrcode">
        <qrcode :value="qrcode" :options="{ width: 150 }"></qrcode>
        <div>{{ appt.id }}</div>
      </div>
      <div class="sub-section">
        <label class="label">
          <img src="@/assets/icons/icon-clock.png" class="icon">วันที่นัด
          <span class="required-mark">*</span>
        </label>
        <p class="value">
          {{ appt.date_time | toThaiDate }}
          <br>
          <template v-if="!appt.is_no_time">{{appt.date_time | toHHMM}} น.</template>
          <template v-else>(ไม่ระบุเวลา)</template>
        </p>
      </div>
      <div class="sub-section">
        <label class="label">
          <img src="@/assets/icons/icon-vaccine.png" class="icon">กิจกรรม
          <span class="required-mark">*</span>
        </label>
        <ul class="activity-list">
          <li v-for="activity in appt.activities" :key="activity.id">{{ activity.name }}</li>
        </ul>
      </div>
      <div class="sub-section">
        <label class="label">
          <img src="@/assets/icons/icon-doctor.png" class="icon">สัตวแพทย์
        </label>
        <p class="value">{{ appt.vet | fullname}}</p>
      </div>
      <div class="sub-section">
        <label class="label">
          <img src="@/assets/icons/icon-notepad.png" class="icon">บันทึกเพิ่มเติม
        </label>
        <p class="value">{{ appt.note | hyphen}}</p>
      </div>
      <div class="sub-section">
        <label class="label">
          <img src="@/assets/icons/icon-info.png" class="icon">หมายเหตุ
        </label>
        <ul class="remark-list" v-if="remarks?.length > 0">
          <li v-for="(remark, index) in remarks" :key="index">{{ remark.note }}</li>
        </ul>
        <div class="value" v-if="remarks?.length === 0">-</div>
      </div>
    </div>
    <template v-if="appt.status === appointmentStatus.NO_RESPOND">
      <div class="section">
        <button
          @click="showCancelDialog = true"
          class="btn btn-primary btn-confirm"
          style="width: 100%;"
        >ยืนยันนัด</button>
      </div>
      <div class="section">
        <button
          @click="isPostponeActive = !isPostponeActive"
          :class="{ 'btn btn-outline btn-postpone': true, 'active': isPostponeActive }"
          style="width: 100%;"
        >แจ้งเลื่อนนัด</button>
        <template v-if="isPostponeActive">
          <PostponeForm
            :vertical="true"
            :requestDate="postponeDate"
            :showCloseIcon="false"
            :showCloseButton="true"
            @close="isPostponeActive = false"
            @confirm="onPostponeConfirm"
          />
        </template>
      </div>
    </template>
    <template v-if="appt.status !== appointmentStatus.NO_RESPOND">
      <div class="section">
        <div class="sub-section">
          <label class="label">สถานะ:</label>
          <span
            :class="{ tag: true, 'large': true, [appt.status]: true}"
          >{{ appt.status | apptStatus }}</span>
        </div>
        <div
          class="sub-section"
          v-if="appt.request_postpone && appt.request_postpone.postpone_request_datetime"
        >
          <label class="label">วันที่ขอเลื่อน:</label>
          <span
            class="value"
            style="margin-left: 0"
          >{{ appt.request_postpone.postpone_request_datetime | toThaiDate }} {{ appt.request_postpone.postpone_request_datetime | toHHMM}} น.</span>
        </div>
        <div class="sub-section" v-if="appt.done_date_time">
          <label class="label">วันที่ทำรายการ:</label>
          <span
            class="value"
            style="margin-left: 0"
          >{{ appt.done_date_time | toThaiDate }} {{ appt.done_date_time | toHHMM}} น.</span>
        </div>
      </div>
    </template>
    <div class="footer">
      <p>* หากจำเป็นต้องเลื่อนนัด กรุณาแจ้งล่วงหน้า อย่างน้อย 2 วัน</p>
      <p>* หากพบความผิดปกติให้รีบพาสัตว์เลี้ยงมาก่อนถึงวันนัดหมาย</p>
      <p>tel: {{ hospital.phone | hyphen }}</p>
      <p v-if="hospital.line_id">@Line: {{ hospital.line_id | hyphen }}</p>
      <p v-if="hospital.facebook_id">Facebook: {{ hospital.facebook_id | hyphen }}</p>
      <p v-if="hospital.instragram_id">Instragram: {{ hospital.instragram_id | hyphen }}</p>
      <p>ที่อยู่: {{ hospital.fullAddress | hyphen }}</p>
      <p>เวลาทำการ: {{ hospital.open_time | hyphen }}</p>
      <p v-if="hospital.website">เว็บไซต์: {{ hospital.website | hyphen }}</p>
    </div>

    <!--- Cancel Dialog -->
    <el-dialog :visible="showCancelDialog" width="360px" center @close="showCancelDialog = false">
      <div class="dialog">
        <div class="header">
          <h2 class="title1">Appointment Confirm</h2>
          <h2 class="title2">ต้องการยืนยันนัด?</h2>
        </div>
        <div class="content">
          <div class="section">
            <div class="sub-section" v-if="pet">
              <label class="label">Pet:</label>
              <div class="value">{{ pet.name }}</div>
            </div>
            <div class="sub-section" v-if="owner">
              <label class="label">Owner:</label>
              <div class="value">{{ `${owner.profile.firstname} ${owner.profile.lastname}` }}</div>
            </div>
            <div class="sub-section">
              <label class="label">
                <img src="@/assets/icons/icon-clock.png" class="icon">วันที่นัด
                <span class="required-mark">*</span>
              </label>
              <p class="value">{{ appt.date_time | toThaiDate }} {{ appt.date_time | toHHMM}} น.</p>
            </div>
            <div class="sub-section">
              <label class="label">
                <img src="@/assets/icons/icon-vaccine.png" class="icon">กิจกรรม
                <span class="required-mark">*</span>
              </label>
              <ul class="activity-list">
                <li v-for="activity in appt.activities" :key="activity.id">{{ activity.name }}</li>
              </ul>
            </div>
            <div class="sub-section">
              <label class="label">
                <img src="@/assets/icons/icon-doctor.png" class="icon">สัตวแพทย์
              </label>
              <p class="value">{{ appt.vet | fullname}}</p>
            </div>
          </div>
        </div>
        <span class="footer">
          <el-button style="width: 48%;" @click="showCancelDialog = false">Cancel</el-button>
          <el-button style="width: 40%;" type="primary" @click="onConfirmCancel">Confirm</el-button>
        </span>
      </div>
    </el-dialog>

    <!--- Postpone Dialog -->
    <el-dialog
      :visible="showPostponeDialog"
      width="360px"
      center
      @close="showPostponeDialog = false"
    >
      <div class="dialog">
        <div class="header">
          <h2 class="title1">Request Postpone</h2>
          <h2 class="title2">ส่งการขอเลื่อนนัดเรียบร้อย</h2>
        </div>
        <div class="content">
          <div class="section">
            <div class="clearfix appt-id">
              Appointment ID
              <strong style="text-align: right; float: right;">{{ appt.id }}</strong>
            </div>
            <div class="sub-section">
              <label class="label">
                <img src="@/assets/icons/icon-clock.png" class="icon">วันที่นัดเดิม
                <span class="required-mark">*</span>
              </label>
              <p class="value">
                {{ appt.date_time | toThaiDate }}
                <br>
                <template v-if="!appt.is_no_time">{{appt.date_time | toHHMM}} น.</template>
                <template v-else>(ไม่ระบุเวลา)</template>
              </p>
            </div>
          </div>
          <div class="section">
            <div class="sub-section">
              <label class="label">
                <img src="@/assets/icons/icon-clock.png" class="icon">วันที่ขอเลื่อน
                <span class="required-mark">*</span>
              </label>
              <p class="value">
                {{ postponeDate | toThaiDate }}
                <br>
                {{ postponeDate | toHHMM}} น.
              </p>
            </div>
          </div>
        </div>
        <span class="footer">
          <el-button style="width: 40%;" type="primary" @click="showPostponeDialog = false">OK</el-button>
        </span>
      </div>
    </el-dialog>
  </ContentBox>
</template>

<script>
import _ from "lodash"
import moment from "moment"
import ContentBox from "@/components/container/ContentBox"
import Avatar from "@/components/avatar/Avatar"
import PetProfile from "@/components/pet/PetProfile"
import PostponeForm from "@/components/appointment/PostponeForm"
import { appointmentStatus } from "../../constants"

export default {
  props: ["appt", "apptState", "qrcode"],
  components: {
    ContentBox,
    Avatar,
    PetProfile,
    PostponeForm
  },
  data() {
    return {
      appointmentStatus: appointmentStatus,
      isPostponeActive: false,
      showPostponeDialog: false,
      showCancelDialog: false,
      postponeDate: new Date()
    }
  },
  computed: {
    hospital() {
      if (this.appt && this.appt.hospital) {
        console.log("🚀 ~ file: ApptDetailPublic.vue:254 ~ hospital ~ this.appt :", this.appt )
        return {
          logo: this.appt.hospital.logo.medium,
          name: this.appt.hospital.name,
          fullAddress: `${this.appt.hospital.address || ""} ${this.appt.hospital
            .district || ""} ${this.appt.hospital.sub_district || ""} ${this
            .appt.hospital.province || ""} ${this.appt.hospital.zip_code ||
            ""}`,
          phone: this.appt.hospital.contact_phones?.length > 0 ? this.appt.hospital.contact_phones[0] : '-',
          open_time: this.appt.hospital.open_time
        }
      }
      return {
        logo: "",
        name: ""
      }
    },
    pet() {
      if (this.appt && this.appt.pet) {
        return this.appt.pet
      }
      return null
    },
    owner() {
      if (this.appt && this.appt.pet && this.appt.pet.owners) {
        if (this.appt.pet.owners?.length > 0) {
          return this.appt.pet.owners[0]
        }
        return null
      }
      return null
    },
    remarks() {
      let remarks = []
      this.appt.activities.forEach(activity => {
        if (activity.note?.length > 0) {
          return activity.note.forEach(notes => {
            remarks.push(notes)
          })
        }
      })
      return _.uniqBy(remarks, "id")
    }
  },
  methods: {
    async onPostponeConfirm(data) {
      if (data.date) {
        if (moment().isBefore(data.date)) {
          const updatedData = {
            action: "request_postpone",
            postpone_request_datetime: data.date
          }
          this.postponeDate = data.date
          await this.updateAppointment(updatedData)
          this.showPostponeDialog = true
          this.isPostponeActive = false
        } else {
          this.$notify({
            message: "กรุณาเลือกระบุวันเวลานัดมากกว่าเวลาปัจจุบัน",
            type: "warning"
          })
        }
      } else {
        this.$notify({
          message: "กรุณาเลือกระบุวันเวลานัด",
          type: "warning"
        })
      }
    },
    async onConfirmCancel() {
      this.updateAppointment({ action: "confirm" })
      this.showCancelDialog = false
    },
    async updateAppointment(data) {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true)
        await this.$store.dispatch("appointment/updateAppointment", {
          params: { id: this.appt.id },
          data: data
        })
        await this.$store.dispatch("appointment/getAppointment", {
          params: { id: this.appt.id }
        })

        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
      } catch (error) {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false)
        await this.$store.dispatch("ui/showFetchErrorMsg")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.postpone-form {
  .btn.btn-outline {
    width: 45% !important;
  }
}
.btn.btn-confirm {
  background-color: #ff6b6b;
  &:hover {
    background-color: darken(#ff6b6b, 5%);
  }
}
.btn.btn-postpone {
  color: #ff6b6b;
  border-color: #ff6b6b;
  &:hover,
  &.active {
    background-color: lighten(#ff6b6b, 20%);
  }
}
.section {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;

  .title {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: normal;
  }
}

.hospital-section {
  padding-top: 0;
  text-align: center;

  .title {
    margin-top: 10px;
  }
}
.appointment-section {
  .qrcode {
    margin-bottom: 15px;
    text-align: center;
  }
  .remark-list {
    margin: 10px 0 0 10px;
    padding: 0;
    list-style-type: none;
    text-indent: -10px;
    font-size: 15px;
    font-weight: normal;
    color: #666;

    li:before {
      content: "-";
      padding-right: 5px;
    }
  }
}

.activity-list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  > li {
    margin: 5px 0;
    padding-left: 20px;
    font-size: 15px;
  }
}

.sub-section {
  margin: 10px 0;
  label {
    color: $light-blue;
  }
  .value {
    font-size: 15px;
    line-height: 1.2em;
    margin: 5px 0 5px 20px;
    color: #333;
  }
}

.footer {
  padding: 10px 0;
  font-size: 15px;
  line-height: 1.2em;
  color: #666;

  p {
    margin: 5px 0;
  }
}

.dialog {
  .header {
    margin-top: -30px;
    padding-bottom: 10px;
    text-align: center;
    border-bottom: 1px solid #ccc;
  }
  .footer {
    display: block;
    padding: 10px 0 0 0;
    text-align: center;
  }
  .title1 {
    margin: 0;
    padding: 0;
    font-size: 18px;
  }
  .title2 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    color: $light-blue;
  }
  .appt-id {
    font-size: 16px;
  }
  .activity-list > li {
    padding-left: 0;
  }
  .value {
    margin-left: 0;
  }
}
</style>


