<template>
  <div class="max-w-lg mx-auto px-4">
    <div class="bg-white shadow-sm px-6 py-4">
      <div class="text-center title">ยินดีต้อนรับสู่ระบบนัดหมาย</div>
      <div class="mt-4">
        <label>เลือกสถานพยาบาล <span class="text-red-700">*</span></label>
        <el-input
          placeholder="ค้นหาสถานพยาบาล"
          v-model="searchValue"
          class="search-input"
          clearable
          @keyup.enter.native="search"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="search"
          ></el-button>
        </el-input>
      </div>
      <div class="h-80 overflow-y-scroll mt-4">
        <div class="flex flex-col-reverse divide-y divide-y-reverse">
          <div v-for="hospital in listHospital" :key="hospital.id">
            <div class="flex items-center gap-2 m-2">
              <div class="flex-none w-14">
                <img :src="hospital.logo.small" />
              </div>
              <div class="flex-auto w-64">
                {{ hospital.name }}
              </div>
              <div class="flex w-32">
                <el-button
                  class="grow"
                  type="primary"
                  plain
                  @click="select(hospital)"
                  >เลือก</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex mt-4">
        <el-button class="grow" type="primary" @click="next()" :disabled="!selectedHospital">
          <div class="flex">
            <div class="grow">
              <span class="ml-2"> เลือกวันเวลานัด</span>
            </div>
            <div>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import ContentBox from '@/components/container/ContentBox.vue';

export default {
  components: {
    // ContentBox
  },
  data() {
    return {
      searchValue: "",
      selectedHospital: null,
      listHospital: [],
    };
  },
  computed: {
    ...mapState({
      hospitals: (state) => state.requestAppointment.hospitals || [],
    }),
  },
  watch: {
    hospitals(val) {
      if (!val?.results) return [];
      let temp = [...val.results]

      this.listHospital = temp.sort((itA, itB) => {
        if (itA.in_hospital) return 1;

        if (itA.name > itB.name) return 1;
        if (itA.name < itB.name) return -1;
      });
    },
  },
  async mounted() {
    this.fetchListHospital();
  },
  methods: {
    async fetchListHospital() {
      try {
        await this.$store.dispatch("requestAppointment/listHospitals");
      } catch (e) {
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    async search() {
      try {
        await this.$store.dispatch("requestAppointment/listHospitals", {
          query: { search: this.searchValue}
        });
      } catch (e) {
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    
    select(hospital) {
      this.selectedHospital = hospital;
    },
    next() {
      this.$store.commit(
        "requestAppointment/SET_HOSPITAL",
        this.selectedHospital
      );
      this.$router.push({
        name: "request-appointment-pets"
      });
    },
  },
};
</script>