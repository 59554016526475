<template>
  <div class="max-w-lg mx-auto px-4">
    <div class="bg-white shadow-sm px-6 py-4">
      <div class="grid justify-center justify-items-center">
        <div>ยินดีต้อนรับสู่ระบบนัดหมาย</div>
        <div class="my-2" v-if="hospital && hospital?.logo">
          <div>
            <img :src="hospital?.logo?.large" width="100px" />
          </div>
        </div>
        <div>
          {{ hospital?.name }}
        </div>
      </div>
      <div class="mt-4">
        <el-form :model="form" ref="FormAppSelectPet">
          <el-form-item
            prop="date"
            :rules="[{ required: true, message: 'date is required' }]"
          >
            <span class="form-label"
              >วันเวลาที่นัด <span class="text-red-500">*</span></span
            >
            <el-date-picker
              v-model="form.date"
              type="date"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
              :picker-options="pickerDateOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            prop="time"
            :rules="[{ required: true, message: 'time is required' }]"
          >
            <el-time-picker
              v-model="form.time"
              :picker-options="{
                format: 'HH:mm',
              }"
              value-format="hh:mm"
              style="width: 100%"
            >
            </el-time-picker>
          </el-form-item>
        </el-form>
      </div>
      <div :class="{'overflow-y-scroll': true , 'h-60': !pet, 'h-25': pet}">
        <el-table
          :data="listPets"
          style="width: 100%"
          :header-cell-style="{ 'background-color': '#f5f8fa' }"
        >
          <el-table-column prop="name" label="ชื่อสัตว์" min-width="150">
            <template slot-scope="scope">
              <div class="flex items-center">
                <div class="flex-none w-14">
                  <el-avatar
                    :size="40"
                    fit="cover"
                    :src="scope.row?.image?.small"
                  ></el-avatar>
                </div>
                <div class="flex-auto">
                  {{ scope.row?.name }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="Type" label="ชนิด" min-width="45" width="50">
            <template slot-scope="scope">
              <div class="flex items-center">
                <img
                  class="pet-type"
                  width="30px"
                  v-if="scope.row?.type?.key == 'DOG'"
                  src="@/assets/icons/icon-dog.png"
                />
                <img
                  class="pet-type"
                  width="30px"
                  v-if="scope.row?.type?.key == 'CAT'"
                  src="@/assets/icons/icon-cat.png"
                />
                <span
                  v-if="
                    scope.row?.type?.key != 'DOG' &&
                    scope.row?.type?.key != 'CAT'
                  "
                  >{{ scope.row?.type?.name.split("/")[0] }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="action">
            <template slot-scope="scope">
              <div class="flex flex-row-reverse">
                <el-button
                  :disabled="!!pet"
                  :type="pet?.id === scope.row.id ? 'primary' : ''"
                  @click="handleSelect(scope.row)"
                  >เลือก</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="grid justify-items-center my-2">
        <el-button plain @click="$router.push({ name: 'petGeneralInfo' })">
          <i class="el-icon-plus"></i> เพิ่มสัตว์เลี้ยง</el-button
        >
      </div>

      <div class="flex my-4">
        <el-button
          class="grow"
          :disabled="!selecePet && !pet"
          type="primary"
          @click="next()"
        >
          <div class="flex">
            <div class="grow">
              <span class="ml-2"> เลือกกิจกรรมนัด</span>
            </div>
            <div>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  components: {},
  data() {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return {
      form: {
        date: date.toISOString().substring(0, 10), //new Date().toISOString().substring(0, 10) +1,
        time: null, //new Date().toISOString().substring(11, 16),
      },
      pickerDateOptions: {
        disabledDate(time) {
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          return time.getTime() < date;
        },
      },
      selecePet: null,
      listPets: [],
      flag: false,
    };
  },
  computed: {
    hospital: get("requestAppointment/hospital"),
    pets: get("owner/pets"),
    pet: get("owner/pet"),
  },

  async mounted() {
    this.fetchData()
  },
  methods: {
    handleSelect(pet) {
      this.selecePet = pet;
      this.$store.commit("requestAppointment/SET_SELECTED_PET", pet);
    },
    next() {
      this.$refs.FormAppSelectPet.validate((valid) => {
        if (valid) {
          this.$store.commit(
            "requestAppointment/SET_APPOINTMENT_TIME",
            this.form.time
          );
          this.$store.commit(
            "requestAppointment/SET_APPOINTMENT_DATE",
            this.form.date
          );

          this.$router.push({
            name: "request-appointment-activities",
          });
        } else {
          return false;
        }
      });
    },
    async fetchData() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("owner/listPets");

        this.listPets = this.pets

        if (this.pet) {
          this.flag = true
          this.$store.commit("requestAppointment/SET_SELECTED_PET", this.pet);
          this.listPets = [this.pet]
        }

      } catch (e) {
        await this.$store.dispatch("ui/showFetchErrorMsg");
      } finally {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      }
    },
  },
};
</script>