<template>
  <div class="max-w-lg mx-auto px-4">
    <div class="bg-white shadow-sm px-6 py-4">
      <div class="grid justify-center justify-items-center">
        <div>ยินดีต้อนรับสู่ระบบนัดหมาย</div>
        <div class="my-2" v-if="hospital && hospital?.logo">
          <div>
            <img :src="hospital?.logo?.large" width="100px" />
          </div>
        </div>
        <div>
          {{ hospital?.name }}
        </div>
      </div>

      <el-row class="my-4">
        <div class="flex w-full">
          <el-button size="" class="grow" type="success"
            >Log in with LINE</el-button
          >
        </div>
      </el-row>
      <el-row class="mt-4">
        <el-form :model="form" ref="FormAppLogin" class="mt-4">
          <el-form-item
            prop="telephone"
            :rules="[{ required: true, message: 'telephone is required' }]"
          >
            <el-input
              prefix-icon="el-icon-user"
              placeholder="Telephone"
              v-model="form.telephone"
              name="telephone"
              type="tel"
              v-validate="'required'"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="password"
            :rules="[{ required: true, message: 'password is required' }]"
          >
            <el-input
              prefix-icon="el-icon-unlock"
              placeholder="Password"
              v-validate="'required'"
              name="password"
              v-model="form.password"
              show-password
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>

      <div class="flex justify-between">
        <div>
          <el-checkbox v-model="form.termChecked"
            >ยอมรับ
            <span>
              <el-link
                href="https://drive.google.com/file/u/3/d/1CB2gkIKR-74QXjfs4HaMZn6zbIX3Tl5l/view?usp=sharing"
                :underline="false"
                target="_blank"
                rel="norefferer"
                >Term and Condition</el-link
              >
            </span>
          </el-checkbox>
        </div>
        <div>
          <router-link :to="{ name: 'hospitalForgetPassword' }"
            >forget password?</router-link
          >
        </div>
      </div>

      <div class="flex flex-col mt-4">
        <div class="flex w-80 self-center">
          <el-button
            :loading="submitButtonLoading"
            class="grow"
            type="primary"
            @click="validateForm"
            >sign in</el-button
          >
        </div>
        <div class="flex self-center">
          <span class="text-red-500">{{ errMessage }}</span>
        </div>
      </div>

      <div class="flex justify-center mt-4">
        <span>ไม่ได้เป็นสมาชิก?</span>
        <router-link :to="{ name: 'register' }">สมัครสมาชิกที่นี่</router-link>
      </div>

      <el-row class="my-4">
        <div class="flex w-full">
          <el-button
            size=""
            class="grow"
            type="primary"
            plain
            @click="NotLogin()"
            >ทำนัดหมายแบบไม่ login</el-button
          >
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
export default {
  data() {
    return {
      submitButtonLoading: false,
      errMessage: null,
      form: {
        telephone: "",
        password: "",
        termChecked: false,
      },
      hospitalId: null,
    };
  },
  computed: {
    hospital: get("requestAppointment/hospital"),
    isAuthenticated: get("auth/isAuthenticated"),
  },
  mounted() {
    if (this.$route.params?.hospital_id) {
      this.hospitalId = this.$route.params?.hospital_id;
      this.$route.params?.hospital_id;
      this.fetchHospital();

      this.$store.commit(
        "requestAppointment/SET_CHANNEL",
        this.$route?.query?.channel
      );

      if (this.isAuthenticated) {
        this.$router.push({ name: "request-appointment-pets" });
      }
    }
  },
  methods: {
    async validateForm() {
      this.$refs.FormAppLogin.validate(async (valid) => {
        if (valid) {
          this.login();
        }
      });
    },
    async login() {
      this.submitButtonLoading = true;
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        await this.$store.dispatch("auth/ownerLogin", {
          data: {
            telephone: this.form.telephone,
            password: this.form.password,
          },
        });

        this.$router.push({ name: "request-appointment-pets" });
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
        this.errMessage = "กรุณากรอก username หรือ password ให้ถูกต้อง";
      } finally {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
        this.submitButtonLoading = false;
      }
    },

    NotLogin() {
      if (this.hospital) {
        this.$router.push({ name: "RequestAppointmentOwner" });
      } else {
        this.$store.dispatch("ui/showFetchErrorMsg", "ไม่พบข้อมูลสถานพยาบาล");
      }
    },
    async fetchHospital() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        // await this.$store.dispatch("hospital/listHospitals");
        await this.$store.dispatch("requestAppointment/getHospital", {
          params: { id: this.hospitalId },
        });

        // let find = this.listHospital.find((it) => {
        //   return +it.id === +this.hospitalId;
        // });

        // if (!find) {
        //   await this.$store.dispatch(
        //     "ui/showFetchErrorMsg",
        //     "ไม่พบข้อมูลสถานพยาบาล"
        //   );
        //   return;
        // }

        // this.$store.commit("requestAppointment/SET_SELECTED_HOSPITAL", find);
      } catch (error) {
        console.log(
          "🚀 ~ file: RequestAppointmentLogin.vue:198 ~ fetchHospital ~ error:",
          error
        );
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      } finally {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>