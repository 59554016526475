<template>
  <div class="max-w-lg mx-auto px-4">
    <div class="bg-white shadow-sm px-6 py-4">
      <div class="grid justify-center justify-items-center">
        <div>ยินดีต้อนรับสู่ระบบนัดหมาย</div>
        <div class="my-2" v-if="hospital && hospital?.logo">
          <div>
            <img :src="hospital?.logo?.large" width="100px" />
          </div>
        </div>
        <div>
          {{ hospital?.name }}
        </div>
      </div>

      <el-row class="my-4">
        <el-col class="text-gray-400">1. วันเวลาที่นัด</el-col>
        <el-col :offset="2">
          {{ displayDateFormat(appointmentDate) }}
          <span class="ml-4">{{ appointmentTime }} น.</span>
        </el-col>
      </el-row>

      <el-row class="my-4" v-show="!isAuthenticated">
        <el-col class="text-gray-400">2. ข้อมูลเจ้าของ </el-col>
        <el-col :span="22" :offset="2">
          <p class="text-clip overflow-hidden">{{ formNoLogin?.owner_name }}</p>
          <p class="text-clip overflow-hidden">
            {{ formNoLogin?.owner_phone }}
          </p>
        </el-col>
      </el-row>

      <el-row class="my-4">
        <el-col class="text-gray-400"
          >{{ isAuthenticated ? 2 : 3 }}. ข้อมูลสัตว์เลี้ยง</el-col
        >
        <el-col :offset="2" v-if="isAuthenticated">
          <div class="flex items-center">
            <div class="flex-none">
              <el-avatar
                :size="40"
                fit="cover"
                :src="pet?.image?.small"
              ></el-avatar>
            </div>
            <div class="basis-1/4 ml-2">
              {{ pet?.name }}
            </div>
            <div class="basis-1/4">
              <img
                width="30px"
                v-if="pet?.type?.key == 'DOG'"
                src="@/assets/icons/icon-dog.png"
              />
              <img
                width="30px"
                v-if="pet?.type?.key == 'CAT'"
                src="@/assets/icons/icon-cat.png"
              />
              <span v-if="pet?.type?.key != 'DOG' && pet?.type?.key != 'CAT'">{{
                pet?.type?.name.split("/")[0]
              }}</span>
            </div>
          </div>
        </el-col>
        <el-col v-else :offset="2">
          <p class="text-clip overflow-hidden">{{ formNoLogin?.pet_name }}</p>
          <p class="text-clip overflow-hidden">
            {{ formNoLogin?.pet_type_desc?.name }}
          </p>
        </el-col>
      </el-row>

      <el-row class="my-4" v-show="state >= 3">
        <el-col class=" ">
          <div class="flex justify-between">
            <div :class="{ 'text-gray-400': state > 3 }">
              {{ isAuthenticated ? 3 : 4 }}. เลือกกิจกรรมนัด<span
                v-show="state === 3"
                class="text-red-500"
                >*</span
              >
            </div>
            <div class="text-gray-400" v-show="state === 3">
              เลือกได้หลายกิจกรรม
            </div>
          </div>
          <p class="text-red-500 text-xs" v-show="!multipleSelection.length">
            เลือกกิจกรรม
          </p>
        </el-col>
        <el-col class="border p-2" v-if="state === 3">
          <el-table
            ref="multipleTable"
            :data="activities"
            :show-header="false"
            :lazy="true"
            class=""
            style="width: 100%"
            height="250"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="29"> </el-table-column>
            <el-table-column label="Name" prop="name"> </el-table-column>
            <el-table-column
              label="price"
              prop="price"
              width="120"
              align="right"
            >
              <template slot-scope="scope"
                >{{ scope.row.price || "-" }} บาท</template
              >
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="22" :offset="2" v-else>
          <div
            class="flex justify-between"
            v-for="(it, id) in multipleSelection"
            :key="id"
          >
            <div class="">{{ it?.name }}</div>
            <div class="text-end">{{ it?.price || "-" }} บาท</div>
          </div>
        </el-col>
      </el-row>

      <div v-show="state >= 4">
        <el-row class="my-4">
          <el-col :class="{ 'text-gray-400': state === 5 }"
            >{{ isAuthenticated ? 4 : 5 }}. ข้อมูลเพิ่มเติม
          </el-col>

          <el-col v-if="state === 4">
            <el-input
              v-model="form.note"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 8 }"
            ></el-input>
          </el-col>
          <el-col :span="22" :offset="2" v-else>
            <p class="text-clip overflow-hidden">{{ form.note }}</p>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row class="my-4">
          <el-col>
            <p class="text-clip">
              <el-checkbox
                id="chk"
                v-if="state === 4"
                v-model="form.share_with_hospital"
                class="pr-2"
              >
              </el-checkbox>
              <strong v-else v-show="!form.share_with_hospital">ไม่</strong>

              <span for="chk">
                ยอมให้ผูกข้อมูล ผู้ใช้ กับ สัตว์เลี้ยง เข้ากับ
                สถานพยาบาลที่ทำการนัดหมาย และยินยอมให้เข้าถึง เวชระเบียน (EHR)
                และข้อมูลการให้คำปรึกษาในอดีต (OPD card) ของสัตว์เลี้ยงของฉันได้
                *สถานพยาบาลอาจโทรติดต่อตามเบอร์โทรที่ให้ไว้เพื่อยืนยันการนัดหมาย
              </span>
            </p>
          </el-col>
        </el-row>
      </div>

      <div class="type-select grid grid-cols-2 gap-2 mt-2">
        <div class="flex">
          <el-button class="grow" @click="goBack()">
            <div class="flex">
              <div>
                <i class="el-icon-arrow-left"></i>
              </div>
              <div class="grow">
                <span class="ml-2"> กลับ</span>
              </div>
            </div>
          </el-button>
        </div>
        <div class="flex">
          <el-button
            class="grow"
            type="primary"
            v-show="state === 3"
            :disabled="!multipleSelection.length"
            @click="state += 1"
          >
            <div class="flex">
              <div class="grow">
                <span class="ml-2"> ถัดไป</span>
              </div>
              <div>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </el-button>
          <el-button
            class="grow"
            type="primary"
            v-show="state === 4"
            @click="state += 1"
            :disabled="!form.share_with_hospital"
          >
            <div class="flex">
              <div class="grow">
                <span class="ml-2"> สรุปการนัดหมาย</span>
              </div>
              <div>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </el-button>
          <el-button
            class="grow"
            type="primary"
            v-show="state === 5"
            @click="submitForm()"
          >
            <div class="flex">
              <div class="grow">
                <span class="ml-2"> ยืนยันการนัดหมาย</span>
              </div>
            </div>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  components: {},
  data() {
    return {
      multipleSelection: [],
      state: 3, // 3 = activity , 4 = note, 5 = preview
      form: {
        note: "",
        share_with_hospital: false,
      },
    };
  },
  computed: {
    hospital: get("requestAppointment/hospital"),
    activities: get("requestAppointment/activities"),
    pet: get("requestAppointment/selectedPet"),
    appointmentDate: get("requestAppointment/appointmentDate"),
    appointmentTime: get("requestAppointment/appointmentTime"),
    channel: get("requestAppointment/channel"),
    formNoLogin: get("requestAppointment/formNoLogin"),

    isAuthenticated: get("auth/isAuthenticated"),
  },
  async mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      let query = {};
      query.hospital = this.hospital.id;
      query.pet_type = this.isAuthenticated
        ? this.pet.type.id
        : this.formNoLogin.pet_type;
      try {
        // await this.$store.dispatch("owner/listPets");
        await this.$store.dispatch("requestAppointment/listActivities", {
          query,
        });
      } catch (e) {
        await this.$store.dispatch("ui/showFetchErrorMsg");
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    goBack() {
      if (this.state === 3) {
        this.$router.go(-1);
      } else {
        this.state -= 1;
        if (this.state < 3) {
          this.state = 3;
        }
      }
    },
    async submitForm() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);

        // form data not login
        let buffer = {
          hospital: this.hospital.id,
          date_time: this.convertDate(
            `${this.appointmentDate} ${this.appointmentTime}:01`
          ).toISOString(),
          note: this.form.note,
          activities: this.multipleSelection.map((it) => ({
            activity: it.id,
          })),
          channel: this.channel,
          share_with_hospital: this.form.share_with_hospital,
        };

        if (this.isAuthenticated) {
          // form data login
          buffer = {
            ...buffer,
            pet: this.pet.id,
          };
        } else {
          buffer = {
            ...buffer,
            ...this.formNoLogin,
          };
        }

        console.log("buffer :>> ", buffer);
        const data = await this.$store.dispatch(
          "requestAppointment/saveReuestAppointment",
          {
            data: buffer,
          }
        );
        if (data.status === "error") {
          throw data;
        }

        if (this.isAuthenticated) {
          this.$router.push({
            name: "petProfile",
            params: { id: this.pet.id },
            query: { tab: "appointment" },
          });
        } else {
          this.$router.push({
            name: "requestAppointmentLogin",
            params: { hospital_id: this.hospital.id },
          });
        }
      } catch (error) {
        console.log(
          "🚀 ~ file: RequestAppointmentActivitiesPage.vue:295 ~ submitForm ~ error:",
          error
        );
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      } finally {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      }
    },
    displayDateFormat(date) {
      return this.$options.filters.toThaiDate(date);
    },
    convertDate(dateTime) {
      let [dateValues, timeValues] = dateTime.split(" ");

      const [year, month, day] = dateValues.split("-");
      const [hours, minutes] = timeValues.split(":");

      const date = new Date(+year, +month - 1, +day, +hours, +minutes);
      return date;
    },
  },
};
</script>