<template>
  <div class="pet-insurance-timeline">
    <div v-if="items.length > 0">
      <div class="flex justify-center font-bold">ซื้อประกันสัตว์เลี้ยง</div>
      <div class="flex justify-center gap-4">
        <div v-for="(item, index) in companiesList" :key="index">
          <div class="grid flex-col justify-items-center" v-show="item.buy_enabled">
            <div>
              <el-link :href="item?.buy_link" target="_blank">
                <el-image style="width: 100px; height: 100px" :src="item?.logo" fit="cover"></el-image>
              </el-link>
            </div>
            <div>{{ item?.name }}</div>
          </div>
        </div>
      </div>
      <el-timeline>
        <el-timeline-item></el-timeline-item>
        <el-timeline-item class="pet-insurance-timline-item" v-for="(item, index) in items" :key="index">
          <PetInsuranceItem :item="item" />
        </el-timeline-item>
        <el-timeline-item></el-timeline-item>
      </el-timeline>
    </div>
    <div class="no-item" v-else>
      <div class="grid flex-col justify-items-center">
        <img width="350px" src="@/assets/images/no-result/no-result-04.png" class="responsive-img" />
        <div class="text-gray-400 text-xl">ไม่มีรายการบันทึก</div>
     
      </div>
    </div>
  </div>
</template>

<script>

import PetInsuranceItem from "./PetInsuranceItem";

export default {
  components: {
    PetInsuranceItem,
  },
  props: ["petID", "viewer"],
  data() {
    return {
      id: this.petID,
      items: [],
      companiesList: [],
    };
  },

  methods: {
    async fetchItems() {
      try {
        await this.$store.dispatch("ui/setIsFullScreenLoading", true);
        var response;
        const data = await this.$store.dispatch("insurance/getListCompanies");
        this.companiesList = data;
        if (this.viewer === "owner") {
          response = await this.$store.dispatch(
            "owner/listPetTimelineInsurance",
            {
              params: { petId: this.id },
            }
          );
        } else if (this.viewer === "hospital") {
          response = await this.$store.dispatch(
            "pet/listTimelineHospitalInsurance",
            {
              params: { petId: this.id },
            }
          );
        } else if (this.viewer === "public") {
          response = await this.$store.dispatch(
            "pet/listPublicTimelineInsurance",
            {
              params: { code: this.id },
            }
          );
        }

        this.items = response;

      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      } finally {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      }
    },
  },

  async mounted() {
    this.fetchItems();
  },
};
</script>

<style lang="scss">
.pet-insurance-timeline {
  .el-timeline {
    margin-left: 20px;
  }

  .el-timeline-item {
    padding-left: 10px;

    &:first-child {
      .el-timeline-item__node--normal {
        display: none;
      }
    }

    &:last-child {
      .el-timeline-item__node--normal {
        display: none;
      }

      padding: 0px;
    }

    .el-timeline-item__node--normal {
      left: -12px;
      width: 34px;
      height: 34px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .el-timeline-item__tail {
      border-width: 4px;
    }

    &.pet-insurance-timline-item {
      .el-timeline-item__node--normal {
        background-image: url("../../assets/icons/icon-insurance.png");
      }
    }
  }
}
</style>