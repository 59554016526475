<template>
  <el-form :model="form" ref="formHealthWeight" class="px-1">
    <el-row class="text-base">
      <el-col :span="12">
        <span>น้ำหนักล่าสุด </span>
      </el-col>
      <el-col :span="12">{{ petWeight }} kg</el-col>
    </el-row>

    <el-form-item
      prop="weight"
      :disabled="flagEdit"
      :rules="[
        { required: true, message: 'weight is required' },
        { type: 'number', message: 'weight must be a number' },
      ]"
    >
      <span class="form-label"
        >น้ำหนัก (kg) <span class="text-red-500">*</span></span
      >
      <el-input-number
        :disabled="flagEdit"
        class="custom-input"
        v-model.number="form.weight"
        :precision="2"
        :min="0"
        :step="0.5"
        :max="100"
        style="width: 100%"
      ></el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  props: {
    emitData: {
      type: Boolean,
      default: false,
    },

    flagEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        weight: 1,
      },
    };
  },
  computed: {
    petWeight: get("owner/pet@weight"),
    healthForm: get("healthRecord/healthForm"),
    flagInsert: get("healthRecord/flagInsert"),
  },
  watch: {
    async emitData() {
      this.$refs.formHealthWeight.validate((valid) => {
        if (valid) {
          this.$emit("resultData", this.form);
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.form.weight = this.petWeight || 0;
    if (this.flagEdit || this.flagInsert) {
      this.form = this.healthForm.weight;
    }
  },
};
</script>

<style lang="scss">
.custom-input {
  .el-input-number__decrease {
    margin-top: 4px;
  }

  .el-input-number__increase {
    margin-top: 4px;
  }
}
</style>