<template>
  <div class="pet-health">
    <Subnavbar back="petOwnerHome" :mini="true">
      <template slot="title">ระบบบันทึกสุขภาพ</template>
    </Subnavbar>

    <div class="form-block standalone">
      <div class="header-block">
        <div class="title">ยินดีต้อนรับสู่ระบบบันทึกสุขภาพ</div>
        <div class="grid justify-items-center">
          <el-image
            style="width: 100px; height: 100px"
            :src="pet?.image?.medium"
            lazy
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="lead">{{ pet?.name }}</div>
        <div class="lead text-gray-400">{{ pet?.type?.name }}</div>
        <div class="lead text-gray-400">{{ pet?.breed?.name }}</div>
      </div>

      <el-form :model="form" :rules="rules" ref="FormHealth">
        <el-form-item prop="date">
          <span class="form-label"
            >วันที่ทำกิจกรรม <span class="red">*</span></span
          >
          <el-date-picker
            v-model="form.date"
            type="date"
            format="dd MMMM yyyy"
            value-format="yyyy-MM-dd"
            :disabled="flagEdit"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <span class="form-label"
            >เลือกชนิดการบันทึก <span class="red">*</span></span
          >
          <div class="grid grid-cols-3 gap-2">
            <div class="flex" v-for="(it, id) in listType" :key="id">
              <el-button
                :disabled="flagEdit"
                :class="{ grow: true }"
                :plain="form.type === id"
                :type="form.type === it.type ? 'primary' : ''"
                @click="setTypePet(it.form, it.type)"
              >
                <div class="flex">
                  <img
                    :src="require(`@/assets/icons/${it.icon}.png`)"
                    width="20px"
                  />
                  <span class="md:ml-2 ml-1"> {{ it.title }}</span>
                </div>
              </el-button>
            </div>
          </div>
        </el-form-item>

        <div v-show="currentType">
          <el-collapse-transition>
            <component
              v-bind:is="currentType"
              :emitData="flagEmit"
              @resultData="resultComponent"
              :flagEdit="flagEdit"
              :code="form.type"
            >
            </component>
          </el-collapse-transition>

          <el-form-item>
            <span class="form-label">ข้อมูลเพิ่มเติม</span>
            <el-input
              :disabled="flagEdit"
              v-model="form.note"
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 6 }"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <div class="grid justify-items-end">
              <div>
                <div class="flex items-center">
                  <span class="mr-2">แนบภาพถ่าย / วิดีโอ / pdf</span>
                  <el-button
                    :disabled="flagEdit"
                    type="primary"
                    style="padding: 5px 5px"
                    @click="$refs.file.click()"
                  >
                    <img
                      src="@/assets/icons/camera.png"
                      alt="iTaam Logo"
                      width="25px"
                    />
                  </el-button>
                </div>
                <input
                  multiple
                  type="file"
                  id="file"
                  ref="file"
                  style="display: none"
                  @change="handleFileUpload"
                />
              </div>
              <div class="justify-self-start">
                <transition-group name="el-zoom-in-top">
                  <p
                    v-for="(file, index) in form.attachment"
                    :key="index"
                    style="line-height: normal"
                    class="text-gray-400"
                  >
                    <i class="el-icon-tickets"></i>
                    {{ shortenFilename(file?.name) }}
                    <el-link type="danger" @click="removeFile(index)">
                      <i class="el-icon-close red"></i
                    ></el-link>
                  </p>
                </transition-group>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <span class="form-label">วันที่ครั้งถัดไป</span>
            <el-date-picker
              v-model="form.next_date"
              type="date"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <span class="form-label">วันที่แจ้งเตือน</span>
            <p class="text-xs text-gray-400">
              ต้องเลือกวันที่ครั้งถัดไปก่อน ถึงจะเลือกวันแจ้งเตือนได้
            </p>
            <el-date-picker
              v-model="form.notify_date"
              type="date"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-checkbox label="" v-model="form.share_with_hospital">
              ยินยอมให้สถานพยาบาลเข้าถึง เวชระเบียน (EHR)
              ประวัติที่บันทึกลงไปได้
            </el-checkbox>
          </el-form-item>
          <el-form-item>
            <div class="type-select grid grid-cols-2 gap-2">
              <div class="flex">
                <el-button class="grow" @click="$router.go(-1)">
                  <div class="flex">
                    <div>
                      <i class="el-icon-arrow-left"></i>
                    </div>
                    <div class="grow">
                      <span class="ml-2"> กลับ</span>
                    </div>
                  </div>
                </el-button>
              </div>
              <div class="flex">
                <el-button class="grow" type="primary" @click="submitForm">
                  <div class="flex">
                    <div class="grow">
                      <span class="ml-2"> บันทึก</span>
                    </div>
                    <div>
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>
                </el-button>
              </div>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <el-dialog
      title="ยืนยันการแก้ไข"
      custom-class="el-dialog-preview"
      :close-on-click-modal="false"
      :visible.sync="dialogConfirm"
      center
    >
      <div class="grid justify-items-center">
        <div class="w-2/3 whitespace-pre-line">
          <el-row class="mt-2">
            <el-col class="text-sm text-gray-400">วันที่ครั้งถัดไป</el-col>
            <el-col class="text-base" v-if="healthForm?.next_date">{{
              displayDateFormat(healthForm?.next_date)
            }}</el-col>
            <el-col v-else>-</el-col>
          </el-row>
          <el-row class="mt-2">
            <el-col class="text-sm text-gray-400">วันที่แจ้งเตือน</el-col>
            <el-col class="text-base" v-if="healthForm?.notify_date">{{
              displayDateFormat(healthForm?.notify_date)
            }}</el-col>
            <el-col v-else>-</el-col>
          </el-row>
        </div>
        <div class="w-10/12 whitespace-pre-line">
          <el-row>
            <el-col>
              <span
                v-if="healthForm.share_with_hospital"
                class="text-lg font-extrabold"
                >ยินยอม</span
              >
              <span v-else class="text-lg font-extrabold">ไม่ยินยอม</span>
              ให้สถานพยาบาลเข้าถึง เวชระเบียน (EHR) ประวัติที่บันทึกลงไปได้
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogConfirm = false">ยกเลิก</el-button>
        <el-button type="primary" @click="updateHealthRecoard()"
          >ยืนยัน</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash/";
import { get } from "vuex-pathify";

import Subnavbar from "@/components/navigation/Subnavbar";
import PetHealthTypeFoodForm from "@/components/pet/PetHealthTypeFoodForm.vue";
import PetHealthTypePreventionForm from "@/components/pet/PetHealthTypePreventionForm.vue";
import PetHealthTypeSickForm from "@/components/pet/PetHealthTypeSickForm.vue";
import PetHealthTypeWeightForm from "@/components/pet/PetHealthTypeWeightForm.vue";

export default {
  components: {
    Subnavbar,
    PetHealthTypePreventionForm,
    PetHealthTypeFoodForm,
    PetHealthTypeSickForm,
    PetHealthTypeWeightForm,
  },
  data() {
    return {
      currentType: "",
      flagEmit: false,
      // flagEdit: false,
      dialogConfirm: false,
      form: {
        pet: "",
        date: new Date().toISOString().substring(0, 10),
        type: null, // PREVENTION = 0, SICK = 1, FOOD = 2, BEHAVIOR = 3, WEIGHT = 4, OTHER = 5
        note: "",
        next_date: null,
        notify_date: null,
        share_with_hospital: false,
        attachment: [],
      },
      rules: {
        date: [
          {
            required: true,
            message: "Please pick a date",
            trigger: "change",
          },
        ],
      },
      listType: [
        {
          type: 0,
          title: "การป้องกัน",
          form: "PetHealthTypePreventionForm",
          icon: "prevention",
        },
        {
          type: 1,
          title: "ป่วย/ผิดปกติ",
          form: "PetHealthTypeSickForm",
          icon: "sick",
        },
        {
          type: 2,
          title: "อาหาร",
          form: "PetHealthTypeFoodForm",
          icon: "food",
        },
        {
          type: 3,
          title: "พฤติกรรม",
          form: "PetHealthTypeSickForm",
          icon: "behavior",
        },
        {
          type: 4,
          title: "น้ำหนัก",
          form: "PetHealthTypeWeightForm",
          icon: "weight",
        },
        {
          type: 5,
          title: "อื่นๆ",
          form: "PetHealthTypeSickForm",
          icon: "orther",
        },
      ],
    };
  },
  computed: {
    pet: get("owner/pet"),
    healthForm: get("healthRecord/healthForm"),
    flagEdit: get("healthRecord/flagEdit"),
  },
  created() {
    if (!_.isEmpty(this.healthForm)) {

      switch (this.healthForm.type) {
        case 0:
          this.currentType = "PetHealthTypePreventionForm";
          break;
        case 1:
          this.currentType = "PetHealthTypeSickForm";
          break;
        case 2:
          this.currentType = "PetHealthTypeFoodForm";
          break;
        case 3:
          this.currentType = "PetHealthTypeSickForm";
          break;
        case 4:
          this.currentType = "PetHealthTypeWeightForm";
          break;
        case 5:
          this.currentType = "PetHealthTypeSickForm";
          break;

        default:
          break;
      }

      this.form = { ...this.healthForm };
    }
  },
  methods: {
    setTypePet(currentType, type) {
      this.currentType = currentType;
      this.form.type = type;
    },
    checkTye() {},
    resultComponent(item) {
      delete this.form?.prevention_items;
      delete this.form?.detail;
      delete this.form?.food_items;
      delete this.form?.weight;

      switch (this.form.type) {
        case 0:
          this.form["prevention_items"] = item;
          break;
        case 1:
        case 3:
        case 5:
          this.form["detail"] = item;
          break;
        case 2:
          this.form["food_items"] = item;
          break;
        case 4:
          this.form["weight"] = item;
          break;
      }
      this.form.pet = this.pet.id;
      this.$store.commit("healthRecord/SET_HEALTH_FORM", this.form);
      if (!this.flagEdit) {
        this.$router.push({ name: "petHealthRecoard" });
      } else {
        this.dialogConfirm = true;
      }
    },
    handlePreview(file) {
      this.form.attachment.push(file);
    },
    removeFile(index) {
      this.form.attachment.splice(index, 1);
    },
    handleFileUpload(file) {
      if (!file) return false;

      this.form.attachment.push(file.target.files[0]);
    },
    shortenFilename(fileName) {
      var file_name = fileName;
      if (file_name.length > 35) {
        return (file_name =
          file_name.substr(0, 35) + "..." + file_name.substr(-7));
      } else {
        return fileName;
      }
    },
    submitForm() {
      this.$refs.FormHealth.validate((valid) => {
        if (valid) {
          this.flagEmit = !this.flagEmit;
          // alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    displayDateFormat(date) {
      return this.$options.filters.toThaiDate(date);
    },
    async updateHealthRecoard() {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      console.log("this.healthForm :>> ", this.healthForm);
      try {
        await this.$store.dispatch("healthRecord/updateHealthRecord", {
          params: { id: this.healthForm.id },
          data: {
            next_date: this.healthForm.next_date,
            notify_date: this.healthForm.notify_date,
            share_with_hospital: this.healthForm.share_with_hospital,
          },
        });

        this.$store.commit("healthRecord/SET_HEALTH_FORM", {});

        this.$router.push({
          name: "petProfile",
          params: { id: this.pet.id },
          query: { tab: "diary" },
        });
      } catch (error) {
        await this.$store.dispatch("ui/showFetchErrorMsg", error.detail);
      } finally {
        await this.$store.dispatch("ui/setIsFullScreenLoading", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pet-health {
  margin-top: 0px;
  padding: 0px 10px 40px 10px;
}

.form-block {
  &.standalone {
    @extend %block-container;
  }

  @extend %form-style;

  .form-submit-btn {
    width: 160px;
  }

  .el-button {
    max-height: 40px;
    padding: 12px 5px;
  }

  .type-select {
    .el-button {
      width: 48%;
    }
  }

  .el-checkbox {
    white-space: pre-wrap;
    display: flex;
  }

  .btn-remove {
    background: none;
    padding: 8px;
    border: none;
    // width: 30px;
    height: 100%;

    img {
      width: 15px;
      height: 15px;
    }
  }

  .el-input:not(first-child) {
    margin-top: 4px;
  }

  @media only screen and (max-width: 600px) {
    img {
      width: 15px;
    }
  }
}
</style>