import Vue from 'vue'
import Router from 'vue-router'

import requestAppointmentRoutes from './requestAppointmentRoutes'

Router.prototype.open = function (routeObject) {
  const {href} = this.resolve(routeObject)
  window.open(href, '_blank')
}

// Layout
import PetOwnerLayout from '@/layouts/PetOwnerLayout.vue'

// Pages
import ApptDetailPublicPage from '@/pages/appointment/ApptDetailPublicPage.vue'
import ApptDetailPDF from "@/pages/appointment/ApptDetailPDF"
import PetProfilePublicPage from '@/pages/pet/PetProfilePublicPage.vue'

const AppointmentPrintSlip = () => import('@/pages/appointment/AppointmentPrintSlip')

// Routes
import petOwnerRoutes from './petOwnerRoutes'

Vue.use(Router)

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  routes: [
    {
      path: "/",
      // name: "home",
      component: PetOwnerLayout,
      meta: {
        requiresAuth: false
      },
      children: [
        ...petOwnerRoutes,
        ...requestAppointmentRoutes,
      ],
      // beforeEnter: (to, from, next) => {
      //   const user_type = localStorage.getItem("user_type");
      //   const token = localStorage.getItem("token");
      //   if (token != null && user_type != null) {
      //     if (user_type == "pet_owner") next({ name: "petOwnerHome" });
      //   } else {
      //     next();
      //   }
      // }
    },
    {
      path: '/appointment/:id/pdf',
      name: 'appointment-detail-pdf',
      component: ApptDetailPDF
    },
    {
      path: '/appointment/:id/print/slip',
      name: 'appointment-print-slip',
      component: AppointmentPrintSlip
    },
		{
			path: '/public/appointment/:id',
			name: 'public-appointment-detail',
			component: ApptDetailPublicPage,
			meta: {
				requiresAuth: false
			}
    },
    {
			path: '/public/pet/code/:code',
			name: 'public-pet-profile-detail',
			component: PetProfilePublicPage,
			meta: {
				requiresAuth: false
			}
    },
		{
			path: '*',
			redirect: '/'
		}
	]
})

function fetchFieldValue(obj, prop) {

  if(typeof obj === 'undefined') {
      return false;
  }

  var _index = prop.indexOf('.')
  if(_index > -1) {
      return fetchFieldValue(obj[prop.substring(0, _index)], prop.substr(_index + 1));
  }

  return obj[prop];
}

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		if (to.meta.permissions != null) {
			const token = localStorage.getItem('token')
			const user_type = localStorage.getItem('user_type')
			if (to.meta.permissions.indexOf(user_type) > -1 && token != null) {

        if (to.meta.plan_permissions != null) {
          const plan = JSON.parse(localStorage.getItem('currentPlan'))
          to.meta.plan_permissions.forEach(permission => {
            if (plan && !fetchFieldValue(plan, permission))
              next({name: 'hospitalError403'})
          });
        }

        next()

			} else {
				let routeName = 'home'

				to.meta.permissions.forEach(permission => {
					switch (permission) {
						case 'pet_owner':
							routeName = 'petOwnerLogin'
							break
						case 'hospital_admin':
							routeName = 'login'
							break
						default:
							break
					}
        })

				next({
					name: routeName,
					query: { redirect: to.fullPath }
				})
			}
		} else {
			alert(
				`Error, route ${
					to.name
				} require a permissions meta when requiresAuth is true.`
			)
			next({
				path: from.fullPath
			})
    }
	} else {
		next() // make sure to always call next()!
	}
})

router.afterEach((to, from) => {
  if (!from.name) return

  window.history.replaceState(
    {
      ...window.history.state,
      previousPath: from.fullPath
    },
    ''
  )
})

export default router

export const back = (fallbackLocation = '/') => {
  const {previousPath} = window.history.state

  if (!previousPath) return router.replace(fallbackLocation)

  return router.back()
}
