<template>
  <div class="px-1">
    <el-form-item>
      <span class="form-label">หัวเรื่อง</span>
      <el-input
        :disabled="flagEdit"
        v-model="form.subject"
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 6 }"
      >
      </el-input>
    </el-form-item>

    <el-form-item>
      <span class="form-label">ป้ายกำกับ</span>
      <el-select
        :disabled="flagEdit"
        v-model="form.tags"
        multiple
        filterable
        allow-create
        default-first-option
        reserve-keyword
        :loading="loading"
      >
        <el-option
          v-for="(item, index) in tagsList"
          :key="index"
          :label="item?.name"
          :value="item?.name"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  props: {
    emitData: {
      type: Boolean,
      default: false,
    },
    flagEdit: {
      type: Boolean,
      default: false,
    },
    code: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {
        subject: "",
        tags: [],
      },
      tagsList: [],
      list: [],
      loading: false,
    };
  },
  computed: {
    healthForm: get("healthRecord/healthForm"),
    flagInsert: get("healthRecord/flagInsert"),
  },
  watch: {
    async emitData() {
      this.$emit("resultData", this.form);
    },
    code() {
      this.fetchData();
    },
  },

  mounted() {
    this.fetchData();

    if (this.flagEdit || this.flagInsert) {
      this.form = this.healthForm.detail;
    }
  },

  methods: {
    async fetchData() {
      await this.$store.dispatch("ui/setIsFullScreenLoading", true);
      const { results } = await this.$store.dispatch(
        "healthRecord/getListTags",
        {
          query: { type: this.code },
        }
      );
      this.tagsList = results;

      await this.$store.dispatch("ui/setIsFullScreenLoading", false);
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.tagsList = this.list.filter((item) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.tagsList = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  display: flex;
}

.el-tag.el-tag--info {
  background-color: #005972;
  border-color: #e9e9eb;
  color: #ffffff;
}
</style>