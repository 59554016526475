import * as vuexHelper from "../helpers/vuexHelper";
import { coreAPI } from "../../services/apis";
import * as endpoints from "../../services/endpoints";

const config = {
  default: {
    api: coreAPI
  },
  state: {
    insuranceFlagEdit: false,
    insuranceForm: null,
    insuranceClaimForm: null,
  },
  asyncActions: [
    {
      name: "getListCompanies",
      method: "get",
      url: () => `${endpoints.INSURANCE}companies/`,
    },
    {
      name: "getListPlan",
      method: "get",
      url: () => `${endpoints.INSURANCE}plans/`,
    },
  
  ]
};

export default vuexHelper.createStoreModule(config);
